import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Typography,
} from "@material-ui/core";
import { Paper } from '@mui/material'
import {
  Send,
  Save,
  Cancel,
} from "@material-ui/icons";
import styles from "../styles";
import {
  MButton,
  MInput,
  MSelect,
} from "../../../../components/form";
import AutocompleteMultiselect from "../../../../components/form/select-multiple";
import {
  createBlastingNotif,
  organizationsBLastingNotif,
  updateBlastingNotif,
  usersBLastingNotif,
} from "../../../../redux/blastingNotif/blastingNotif.action";
import { debounce } from "lodash";
import moment from "moment";

class BlastingNotifForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blasting_id: null,
      error_message: null,
      formData: {
        subject: "",
        message: "",
        target_type: "all",
        user_ids: [],
        organization_ids: [],
        notification_type: ["push_notif"],
        schedule_type: "now",
        schedule_date_time: null,
        status: "sent",
      },
      filterState: {
        page: 1,
        limit: 10,
        search: ''
      },
      users: [],
      organizations: [],
    };
  }

  paperRef = createRef()
  scrollPositionRef = createRef(0)
  lastPageRef = createRef(1)

  resetState = () => {
    this.setState(
      (prevState) => ({
        blasting_id: null,
        error_message: null,
        formData: {
          subject: "",
          message: "",
          target_type: "all",
          user_ids: [],
          organization_ids: [],
          notification_type: ["push_notif"],
          schedule_type: "now",
          schedule_date_time: null,
          status: "sent",
        },
        filterState: {
          page: 1,
          limit: 10,
          search: ''
        },
        users: [],
        organizations: [],
      })
    );
  }

  handleInputChange = (field, value) => {
    this.setState((prevState) => ({
      ...prevState,
      error_message: {
        ...prevState.error_message,
        [field]: undefined
      },
      formData: {
        ...prevState.formData,
        [field]: value,
      },
    }));
  };

  handleFilterState = (field, value) => {
    this.setState((prevState) => ({
      filterState: {
        ...prevState.filterState,
        [field]: (prevState)
      }
    }))
  }

  handleScroll = (page, type) => {
    if (type === "user") {
      if (this.state.filterState.page < this.props?.usersBlasting?.meta?.totalPages) {
        this.setState(prevState => ({
          ...prevState,
          filterState: {
            ...prevState.filterState,
            page
          }
        }))
        this.props.usersBLastingNotif({page: page, limit: 10, search: this.state.filterState.search})
      }
    }

    if (type === 'organization') {
      if (this.state.filterState.page && this.props?.organizationsBlasting?.meta?.totalPages > 0) {
        this.setState(prevState => ({
          ...prevState,
          filterState: {
            ...prevState.filterState,
            page
          }
        }))
        this.props.organizationsBLastingNotif({page: page, limit: 10, search: this.state.filterState.search})
      }
    }
  }

  appendDataUsers = () => {
    this.setState(prevState => ({
      ...prevState,
      users: [...prevState.users, ...this.props.usersBlasting?.data]
    }))
  }

  appendDataOrganizations = () => {
    this.setState(prevState => ({
      ...prevState,
      organizations: [...prevState.organizations, ...this.props.organizationsBlasting?.data]
    }))
  }

  componentDidUpdate(prevProps, prevState) {
    const { usersBlasting, organizationsBlasting, blastingDetail, is_detail } = this.props;

    if(prevProps.usersBlasting?.data !== usersBlasting?.data) {
      this.appendDataUsers()
    }

    if(prevProps.organizationsBlasting?.data !== organizationsBlasting?.data) {
      this.appendDataOrganizations()
    }

    if(this.state.filterState.page > 1 && this.state.filterState.page > this.lastPageRef.current) {
      this.lastPageRef.current = this.state.filterState.page
    }

    if (prevProps.blastingDetail !== blastingDetail) {
      if (is_detail === false) {
        const newForm = {}
        newForm['subject'] = blastingDetail.subject
        newForm['message'] = blastingDetail.message
        newForm['target_type'] = blastingDetail.target_type
        newForm['schedule_type'] = blastingDetail.schedule_type
        newForm['status'] = blastingDetail.status

        if (blastingDetail.target_type === 'user') {
          this.props.usersBLastingNotif({ page: 1, limit: 10 });
          newForm['user_ids'] = blastingDetail.user_data.map((e) => {return { id: e.id, name: e.name }});
          newForm['organization_ids'] = undefined;
        }
  
        if (blastingDetail.target_type === 'organization') {
          this.props.organizationsBLastingNotif({ page: 1, limit: 10 });
          newForm['user_ids'] = undefined;
          newForm['organization_ids'] = blastingDetail.organizations.map((e) => {return { id: e.id, name: e.name }});
        }
  
        if (blastingDetail.schedule_type !== 'now') {
          newForm['schedule_date_time'] = blastingDetail.schedule_date_time;
        }
  
        this.setState((prevState) => ({
          ...prevState,
          blasting_id: blastingDetail.id,
          formData: {
            ...prevState.formData,
            ...newForm
          },
        }))
      }
    }
  }

  handleSubmit = (status) => {
    const { formData, blasting_id } = this.state

    const error = {}
    if (formData && formData.status === 'sent') {
      if (!formData.subject) error['subject'] = 'Subject is required.';
      if (!formData.message) error['message'] = 'Message cannot be empty.';
      if (!formData.target_type) error['target_type'] = 'Target Type is required.';
      if (!formData.schedule_type) error['schedule_type'] = 'Schedule Type is required.';
  
      if (formData.target_type === 'user' && formData.user_ids.length < 1) error['user_ids'] = 'Select at least 1 user.';
      if (formData.target_type === 'organization' && formData.organization_ids.length < 1) error['organization_ids'] = 'Select at least 1 organization.';
      if (formData.schedule_type !== 'now' && !formData.schedule_date_time) error['schedule_date_time'] = 'Schedule Date & Time is required.';
      if (formData.schedule_type !== 'now' && formData.schedule_date_time && moment().isAfter(moment(formData.schedule_date_time))) error['schedule_date_time'] = 'Input Schedule is invalid.';
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        error_message: error
      })
    )

    if (Object.keys(error).length) return

    let payload = {
      ...formData,
      status
    }

    if (formData.target_type === 'user') {
      payload.user_ids = formData.user_ids.map(user => user.id)
      delete payload.organization_ids
    } else if (formData.target_type === 'organization') {
      payload.organization_ids = formData.organization_ids.map(organization => organization.id)
      delete payload.user_ids
    } else if (formData.target_type === 'all') {
      delete payload.organization_ids
      delete payload.user_ids
    }

    if (formData.schedule_type === 'now') {
      delete payload.schedule_date_time
    }

    if (!blasting_id) {
      this.props.createBlastingNotif(payload);
    } else {
      this.props.updateBlastingNotif(blasting_id, payload);
    }

    this.resetState();
  };

  renderForm = () => {
    const { classes } = this.props;
    const { error_message, formData, is_detail, blasting_id } = this.state;

    const targetOptions = [
      { id: "all", name: "All Users" },
      { id: "user", name: "Specific Users" },
      { id: "organization", name: "Organizations" },
    ];

    const scheduleOptions = [
      { id: "now", name: "Send Now" },
      { id: "scheduled", name: "Schedule for Later" },
    ];

    return (
      <div>
        <Typography className={classes.paperTitle}>
        {`${!is_detail ? !blasting_id ? 'Create' : 'Update' : 'Create'} Blasting Notifications`}
        </Typography>
        <MInput
          classNameFC={classes.formControl}
          name="subject"
          label="Subject *"
          placeholder="Enter the subject"
          value={formData.subject}
          onChange={(e) => this.handleInputChange("subject", e.target.value)}
          error={
            error_message && error_message.subject ?
              error_message.subject : undefined
          }
          autoComplete="off"
        />
        <MInput
          classNameFC={classes.formControl}
          name="message"
          label="Message *"
          placeholder="Enter the message content"
          value={formData.message}
          onChange={(e) => this.handleInputChange("message", e.target.value)}
          error={
            error_message && error_message.message ?
              error_message.message : undefined
          }
          autoComplete="off"
          multiline
          rows={5}
          rowsMin={5}
          rowsMax={20}
        />
        <div spacing={2} style={{display: 'flex', gap: '12px'}}>
          <div xs={6} style={{width: '50%'}}>
            <MSelect
              classNameFC={classes.formControl}
              name="target_type"
              label="Target Type"
              keyPair={["id", "name"]}
              options={targetOptions}
              value={!formData.target_type ? 'all' : formData.target_type}
              onChange={(e) => {
                if (e.target.value === "user") {
                  this.props.usersBLastingNotif({ page: 1, limit: 10 });
                } else if (e.target.value === "organization") {
                  this.props.organizationsBLastingNotif({ page: 1, limit: 10 });
                }
                this.handleInputChange("target_type", e.target.value);
              }}
              error={
                error_message && error_message.target_type ?
                  error_message.target_type : undefined
              }
            />
            {formData.target_type === "user" && <AutocompleteMultiselect
              name="user_ids"
              label="Users"
              placeholder="Select Users"
              options={this.state.users}
              value={formData.user_ids}
              scrollToNextPage={(page) => this.handleScroll(page, "user")}
              onInputChange={debounce(({page, search, limit}) => {
                this.setState(prevState => ({
                  ...prevState,
                  users: [],
                  filterState: {
                    ...prevState.filterState,
                    page: 1
                  }
                }))
                this.props.usersBLastingNotif({ page: 1, search, limit});
              }, 1000)}
              onChange={(ids) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    formData: {
                      ...prevState.formData,
                      user_ids: ids
                    }
                  }
                ))
              }}
              error={
                error_message && error_message.user_ids ?
                  error_message.user_ids : undefined
              }
            />}
            {formData.target_type === "organization" && <AutocompleteMultiselect
              name="organization_ids"
              label="Organizations"
              placeholder="Select Organizations"
              options={this.state.organizations}
              value={formData.organization_ids}
              scrollToNextPage={(page) => this.handleScroll(page, "organization")}
              onInputChange={debounce(({page, search, limit}) => {
                this.setState(prevState => ({
                  ...prevState,
                  organizations: [],
                  filterState: {
                    ...prevState.filterState,
                    page: 1
                  }
                }))
                this.props.organizationsBLastingNotif({ page: 1, search, limit});
              }, 1000)}
              onChange={(ids) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    formData: {
                      ...prevState.formData,
                      organization_ids: ids
                    }
                  }
                ))
              }}
              error={
                error_message && error_message.organization_ids ?
                  error_message.organization_ids : undefined
              }
            />}
          </div>
          <div xs={6} style={{width: '50%'}}>
            <MSelect
              classNameFC={classes.formControl}
              name="schedule_type"
              label="Schedule Type *"
              keyPair={["id", "name"]}
              options={scheduleOptions}
              value={!formData.schedule_type ? 'now' : formData.schedule_type}
              onChange={(e) =>
                this.handleInputChange("schedule_type", e.target.value)
              }
              error={
                error_message && error_message.schedule_type ?
                  error_message.schedule_type : undefined
              }
            />
            {formData.schedule_type === "scheduled" && (
              <MInput
                classNameFC={classes.formControl}
                name="schedule_date_time"
                label="Schedule Date & Time"
                type="datetime-local"
                value={
                  (
                    !blasting_id ?
                    formData.schedule_date_time :
                    formData.schedule_date_time.slice(0, 16)
                  ) || ""
                }
                onChange={(e) =>
                  this.handleInputChange("schedule_date_time", e.target.value)
                }
                error={
                  error_message && error_message.schedule_date_time ?
                    error_message.schedule_date_time : undefined
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { classes, isLoading } = this.props;

    let button = null;

    const draft = (
      <MButton
        className={`${classes.btnSaveDraft} ${classes.mr}`}
        label="Save Draft"
        icon={<Save />}
        onClick={() => this.handleSubmit("draft")}
        loading={isLoading}
      />
    );

    const submit = (
      <MButton
        className={classes.btnSubmit}
        label={`${!this.props.is_detail ? !this.state.blasting_id ? "Submit" : "Update" : "Submit"}`}
        icon={<Send />}
        onClick={() => this.handleSubmit("sent")}
        loading={isLoading}
      />
    )

    const cancel = (
      <MButton
        className={classes.btnCancelForm}
        label="Cancel Form"
        icon={<Cancel />}
        onClick={() => {
          this.resetState();
          this.props.resetForm();
        }}
      />
    )
    if (!this.props.is_detail) {
      if (!this.state.blasting_id) {
        button = (
          <div className={classes.btnContainer}>
            {draft}
            {submit}
          </div>
        )
      } else {
        button = (
          <div className={classes.btnContainerBlasting}>
            {draft}
            {submit}
            {cancel}
          </div>
        )
      }
    } else {
      button = (
        <div className={classes.btnContainer}>
          {draft}
          {submit}
        </div>
      )
    }

    return (
      <Paper variant="outlined" className={classes.paper}>
        <form>
          {this.renderForm()}
          {button}
        </form>
      </Paper>
    );
  }
}



const mapStateToProps = ({ blastingNotif }) => ({
  usersBlasting: blastingNotif.blastingNotifDataUsers.data,
  organizationsBlasting: blastingNotif.blastingNotifDataOrganizations.data,
  blastingDetail: blastingNotif.blastingDetail,
  isLoading: blastingNotif.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  usersBLastingNotif: (params) => dispatch(usersBLastingNotif(params)),
  organizationsBLastingNotif: (params) => dispatch(organizationsBLastingNotif(params)),
  createBlastingNotif: (params) => dispatch(createBlastingNotif(params)),
  updateBlastingNotif: (id, params) => dispatch(updateBlastingNotif(id, params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlastingNotifForm));
