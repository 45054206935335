import {ENDPOINTS, apiService} from '../config';

export const getUsers = (params) => {
  return apiService.get(
      ENDPOINTS.USER.ALL,
      {params},
  );
};

export const getProfile = (id, params) => {
  return apiService.get(
      ENDPOINTS.USER.PROFILE + id,
      {params},
  );
};

export const getUsersAdmin = (params) => {
  return apiService.get(
      ENDPOINTS.USER.ALL_ADMIN,
      {params},
  );
};

export const getNewUsers = (params) => {
  return apiService.get(
      ENDPOINTS.USER.MANAGER,
      {params},
  );
};

export const getManagers = (params) => {
  return apiService.get(
      ENDPOINTS.USER.MANAGER,
      {params},
  );
};

export const getManagersId = (id) => {
  return apiService.get(
      ENDPOINTS.USER.MANAGER_ID + id,
  );
};

export const getRoles = () => {
  return apiService.get(
      ENDPOINTS.USER.ROLES,
  );
};

export const getJobLists = () => {
  return apiService.get(
      ENDPOINTS.USER.JOBS,
  );
};

export const addUser = (params) => {
  return apiService.post(
      ENDPOINTS.USER.ADD,
      params,
      {headers: {'Content-Type': 'multipart/form-data'}},
  );
};

export const addNewUser = (params) => {
  return apiService.post(
      ENDPOINTS.USER.ADDNEW,
      params,
  );
};

export const addUserAdmin = (params) => {
  return apiService.post(
      ENDPOINTS.USER.ADD_ADMIN,
      params,
  );
};

export const updateUser = (id, params) => {
  return apiService.put(
      ENDPOINTS.USER.UPDATE + id,
      params,
      {headers: {'Content-Type': 'multipart/form-data'}},
  );
};

export const updateNewUser = (id, params) => {
  return apiService.put(
      ENDPOINTS.USER.UPDATE_SUPERADMIN + id,
      params,
  );
};

export const updateUserAdmin = (id, params) => {
  return apiService.put(
      ENDPOINTS.USER.UPDATE_ADMIN + id,
      params,
  );
};
export const dropUser = (id) => {
  return apiService.delete(
      ENDPOINTS.USER.DELETE + id,
  );
};

export const checkStatus = () => {
  return apiService.get(
      ENDPOINTS.USER.CHECK_STATUS,
  );
};

export const syncAdvantech = () => {
  return apiService.post(
      ENDPOINTS.USER.SYNC_ADVANTECH,
  );
};

export const cancelAdvSync = () => {
  return apiService.post(
      ENDPOINTS.USER.CANCEL_SYNC,
  );
};

export const exportExcelUser = (params) => {
  return apiService.post(
      ENDPOINTS.USER.EXPORT,
      params,
  );
};

export const importExcelUser = (params) => {
  return apiService.post(
      ENDPOINTS.USER.IMPORT,
      params,
      {headers: {'Content-Type': 'multipart/form-data'}},
  );
};

export const readNotifUser = () => {
  return apiService.post(
      ENDPOINTS.USER.READ_NOTIF,
  );
};

export const cancelImportUser = () => {
  return apiService.post(
      ENDPOINTS.USER.CANCEL_IMPORT,
  );
};

export const downloadExcelTemplate = (params) => {
  return apiService.post(
      ENDPOINTS.USER.DOWNLOAD_TEMPLATE,
      params,
  );
};

export const getBankList = (params) => {
  return apiService.get(
      ENDPOINTS.USER.BANK,
      {params},
  );
};

export const getEmployeeDetail = (id) => {
  return apiService.get(
      ENDPOINTS.USER.DETAIL + id,
  );
};

export const updatePersonalData = (id, params) => {
  return apiService.put(
      ENDPOINTS.USER.UPDATE_PERSONAL + id,
      params,
      {headers: {'Content-Type': 'multipart/form-data'}},
  );
};

export const updateEmploymentData = (id, params) => {
  return apiService.put(
      ENDPOINTS.USER.UPDATE_EMPLOYMENT + id,
      params,
  );
};

export const updateRemoteData = (id, params) => {
  return apiService.put(
      ENDPOINTS.USER.UPDATE_REMOTE + id,
      params,
  );
};

export const updateAccountData = (id, params) => {
  return apiService.put(
      ENDPOINTS.USER.UPDATE_ACCOUNT + id,
      params,
  );
};


export const getContractUser = (params) => {
  return apiService.get(
      ENDPOINTS.USER.CONTRACT,
      {params},
  );
};

export const addContractUser = (params) => {
  return apiService.post(
      ENDPOINTS.USER.ADD_CONTRACT,
      params,
  );
};

export const updateContractUser = (id, params) => {
  return apiService.put(
      ENDPOINTS.USER.UPDATE_CONTRACT + id,
      params,
  );
};

export const updateContractStatus = (id, params) => {
  return apiService.put(
      ENDPOINTS.USER.UPDATE_CONTRACT_STATUS + id,
      params,
  );
};

export const updateEmployeeStatus = (id, params) => {
  return apiService.put(
      ENDPOINTS.USER.UPDATE_EMPLOYEE_STATUS + id,
      params,
  );
};

export const deleteContractUser = (id) => {
  return apiService.delete(
      ENDPOINTS.USER.DELETE_CONTRACT + id,
  );
};

export const getUpdateHistory = (params, id) => {
  return apiService.get(
      ENDPOINTS.USER.UPDATE_HISTORY + id,
      {params},
  );
};

export const updateDeductedLeave = (id, params) => {
  return apiService.put(
      ENDPOINTS.USER.UPDATE_DEDUCTED + id,
      params,
  );
};

export const getLeaveHistory = (params, id) => {
  return apiService.get(
      ENDPOINTS.USER.LEAVE_HISTORY + id,
      {params},
  );
};

export const getUserPersonalLeave = (params, id) => {
  return apiService.get(
      ENDPOINTS.USER.USER_PERSONAL_LEAVE + id,
      {params},
  );
};

export const createUserJobTitle = (params) => {
  return apiService.post(
      ENDPOINTS.USER.CREATE_JOB,
      params,
  );
};

export const createAdjustmentLeave = (params) => {
  return apiService.post(
    ENDPOINTS.USER.ADD_ADJUSTMENT_LEAVE,
    params,
  );
};

export const getListEmployeeType = (params) => {
  return apiService.get(
    ENDPOINTS.USER.EMPLOYEE_TYPE,
    {params},
  );
}