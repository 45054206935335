/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import styles from './styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import defaultImgReimburs from '../../assets/img/default-reimburs.png';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Paper,
  Button,
  Grid,
  Avatar,
  Divider,
  CardMedia,
  Typography,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import {
  Search,
  Refresh,
  CheckCircleOutline,
  CheckCircle,
  Cancel,
  Send,
  SaveAlt,
  Crop,
} from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import {toast, isObject, convDate, checkFileUploadSize, blobToFile, showPopup, handleFilterDateChange} from '../../helpers';
import {
  MInput,
  MKeyBoardDatepicker,
  MButton,
  ModalTitle,
  MSelect,
  MDatepicker,
} from '../../components/form';
import {Datatable} from '../../components';
import _ from 'lodash';
import {
  getReimburseHistory,
  putReimbursStatus,
  resetReimburseStatus,
  exportExcel,
  clearFormMessage,
} from './../../redux/reimburse/reimburse.actions';
import {updateExportNotification} from '../../redux/exportActivity/exportActivity.actions';
import {REIMBURSE_HISTORY_STATE as STATE} from '../../redux/reimburse/reimburse.types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {checkStatus as checkStatusUser} from '../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../redux/organization/organization.actions';
import moment from 'moment';
import {Snackbar, createTheme as themeTemplate} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {Checkbox, FormControlLabel} from '@mui/material';
import {NumericFormat} from 'react-number-format';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ImageCropper from '../../components/cropper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TimelineContent from '@mui/lab/TimelineContent';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import featureFlag from '../../config/featureFlag';
import noAttachment from '../../assets/img/gamification/noAttachment.png';

class Reimburse extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      date: null,
      order: 'desc',
      method: 'all',
      showDetail: false,
      showReject: false,
      showApprove: false,
      detailData: null,
      resetDatatable: null,
      resetPage: null,
      status: 'waiting',
      searchBy: null,
      selectedStatus: 'waiting',
      formData: {
        name: null,
        nik: null,
        job_title: null,
        submit_date: null,
        amount: null,
        date: null,
        account_no: null,
        details: null,
        image: null,
        hr_image: [],
        status: '',
        rejected_reason: '',
        notes: '',
        user_img: null,
        imageURL: null,
        cropped_user_img: null,
        approval_history: null,
        imagePreview: featureFlag.reimburse.enable_attachment ? null : '',
      },
      errorMessage: null,
      checkboxFilter: false,
      errorImageSize: null,
      showCropResult: false,
      isCropping: false,

      snackbar: false,
      snackbarVertical: 'top',
      snackbarHorizontal: 'right',
    };
  }

  checkboxTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  getMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiDialog: {
        paper: {
          margin: '32px',
          padding: '0px 32px 0px 32px',
        },
      },
      MuiGrid: {
        item: {
          padding: '0px',
          marginRight: '0px',
        },
      },
      MuiInputBase: {
        input: {
          '&.Mui-disabled': {
            opacity: 0.6,
          },
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 0,
        },
      },
    },
  })

  tooltipTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '18px !important',
            marginLeft: '6px !important',
            marginTop: '-1px !important',
          },
        },
      },
    },
  })

  timelineTheme = () => createTheme({
    palette: {
      primary: {
        main: featureFlag.reimburse.enable_approval ? '#00AFF0' : '#1976d2',
      },
      secondary: {
        main: featureFlag.reimburse.enable_approval ? '#FFC226' : '#dc004e',
      },
      warning: {
        main: featureFlag.reimburse.enable_approval ? '#F00B38' : '#ff9800',
      },
      success: {
        main: featureFlag.reimburse.enable_approval ? '#008000' : '#4caf50',
      },
    },
    components: {
      MuiTimelineItem: {
        styleOverrides: {
          root: {
            minHeight: featureFlag.reimburse.enable_approval ?
              '46px !important' :
              '70px !important',
          },
        },
      },
      MuiTimelineContent: {
        styleOverrides: {
          root: {
            padding: featureFlag.reimburse.enable_approval ?
              '6px 4px 6px 12px !important' :
              '6px 16px !important',
          },
        },
      },
      MuiTimeline: {
        styleOverrides: {
          root: {
            padding: featureFlag.reimburse.enable_approval ?
              '6px 16px 0px 16px !important' :
              '6px 16px !important',
          },
        },
      },
    },
  });

  snackbarTheme = () => themeTemplate({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            color: '#000000 !important',
            backgroundColor: '#FFFFFF !important',
          },
        },
      },
    },
  });

  accordionTheme = () => createTheme({
    palette: {
      primary: {
        main: featureFlag.reimburse.enable_approval ? '#00AFF0' : '#1976d2',
      },
    },
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: featureFlag.reimburse.enable_approval ? '0px !important' : '48px !important',
            margin: featureFlag.reimburse.enable_approval ?
              '13px 0px 8px 0px !important' :
              '12px 0px !important',
          },
          root: {
            'minHeight': featureFlag.reimburse.enable_approval ? '0px !important' : '48px !important',
            '&.Mui-expanded': {
              'justifyContent': 'space-between',
              'minHeight': featureFlag.reimburse.enable_approval ? '0px !important' : '48px !important',
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            display: '',
            padding: featureFlag.reimburse.enable_approval ? '0px !important' : '16px !important',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: featureFlag.reimburse.enable_approval ?
              '8px 4px 8px 12px !important' :
              '8px !important',
            marginTop: featureFlag.reimburse.enable_approval ? '6px !important' : '0px !important',
          },
        },
      },
    },
  });

  UNSAFE_componentWillMount() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const name = url.searchParams.get('query');

    this.props.checkStatus();
    this.props.checkStatusUser();

    if (name) {
      this.setState({query: name}, () => {
        this.handleDebounceSearch(name);
      });
    } else {
      this.fetchReimburse();
    }
  }

  componentDidMount() {

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {isError, message, trigger, isSuccessPut, isErrorPut, messagePut, exportData, isSuccessPost} = nextProps;
    const {status} = this.state.formData;

    if (trigger === STATE.EXPORT_SUCCESS && exportData) {
      // toast('success', 'File successfully exported & downloaded');
      this.setState({snackbar: true});
      this.props.updateExportNotification({
        fileName: 'Reimbursement History',
        ...exportData,
      });
    }


    if (isError && message && !isObject(message)) toast('error', message);
    if (trigger === STATE.PUT_CHANGE_STATUS_SUCCESS && isSuccessPut) {
      if (status === 'approved') {
        toast('success', 'Successfuly Approved Reimburse');
      } else if (status === 'rejected') {
        toast('success', 'Successfuly Rejected Reimburse');
      }
      this.handleResetForm(true);
      this.fetchReimburse();
      this.props.checkStatus();
    } else if (isErrorPut) {
      toast('error', 'Error Update Leave');
      if (messagePut) {
        this.setState({errorMessage: messagePut});
      }
      setTimeout(() => {
        nextProps.resetReimburseStatus();
      }, 1000);
    }
  }

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      formData: {},
      detailData: {},
      reimburs_id: null,
    });
    if (closeModal) this.setState({showDetail: false, showReject: false, showApprove: false});
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  };

  handleFilterCheckbox = (event) => {
    const {checked} = event.target;

    if (checked) {
      this.setState({
        filterCheckbox: checked,
        startDate: convDate(moment()),
        endDate: convDate(moment()),
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        sortBy: null,
        order: 'desc',
        method: 'all',
        status: 'all',
        searchBy: 'submission_date',
      };
      this.setState(state, () => this.fetchReimburse());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    } else {
      this.setState({
        filterCheckbox: checked,
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        startDate: null,
        endDate: null,
        date: null,
        sortBy: null,
        order: 'desc',
        method: 'all',
        status: 'waiting',
        searchBy: null,
      };
      this.setState(state, () => this.fetchReimburse());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    }
  }

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      startDate: convDate(moment()),
      endDate: convDate(moment()),
      date: null,
      sortBy: null,
      order: 'desc',
      method: 'all',
      status: 'all',
      searchBy: 'submission_date',
    };
    this.setState(state, () => this.fetchReimburse());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: null}, () => this.fetchReimburse());
  }, 500);

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState(
      {[name]: value, page: null},
    );
  };

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null});
  };

  handleButtonFilter = () => {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      method,
      status,
      searchBy,
    } = this.state;

    if (startDate === null || endDate === null) {
      return this.setState({
        errorFilterDate: true,
      });
    }

    this.props.getReimburseHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(status && status !== 'all' && {status}),
      ...(searchBy && searchBy !== 'all' && {searchBy}),
    });
  }

  handleExport = () => {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      method,
      status,
      searchBy,
      completed,
    } = this.state;

    this.props.exportExcel({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(status && status !== 'all' && {status}),
      ...(searchBy && searchBy !== 'all' && {searchBy}),
    });
  }

  handleCloseSnackbar = () => {
    this.setState({snackbar: false});
  };

  renderSnackbar = () => {
    const {snackbar, snackbarVertical, snackbarHorizontal} = this.state;
    const action = (
      <Button color="primary" size="small" onClick={() => this.props.history.push('/export-activity')}>
        View Activity
      </Button>
    );
    return (
      <ThemeProvider theme={this.snackbarTheme()}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbar}
          onClose={this.handleCloseSnackbar}
          message="Your file is in the queue, click here to see the progress"
          key={snackbarVertical + snackbarHorizontal}
          action={action}
        />
      </ThemeProvider>
    );
  }

  renderFilter = () => {
    const {classes, message, exportLoading, exportData, isSuccessPost, reimburseData} = this.props;
    const {query, startDate, endDate, status, searchBy} = this.state;
    const handleDateChange = handleFilterDateChange(this);

    const renderMethodOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        // {id: 'waiting', name: 'Waiting'},
        {id: 'approved', name: 'Approved'},
        {id: 'rejected', name: 'Rejected'},
      ];
      return options;
    };

    const renderSearchByOptions = () => {
      const options = [
        {id: 'submission_date', name: 'Reimburse Date'},
        {id: 'confirmed_date', name: 'Confirmed Date'},
      ];
      return options;
    };

    return (
      <Paper variant='outlined' className={classes.paper}>
        {this.state.filterCheckbox ? (
          <>
            <MInput
              autoFocus
              className={`${classes.filter} ${classes.formControlSearch}`}
              fullWidth={false}
              variant="standard"
              name="query"
              label="Search"
              placeholder="Search keyword"
              value={query}
              onChange={this.handleSearch}
              InputProps={{
                endAdornment: (
                  <IconButton position="end">
                    <Search className={classes.searchIcon} />
                  </IconButton>
                ),
              }}
            />
            <ThemeProvider theme={this.checkboxTheme()}>
              <FormControlLabel
                style={{display: 'inline-flex', verticalAlign: 'bottom'}}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.filterCheckbox}
                    onChange={(e) => this.handleFilterCheckbox(e)}
                  />
                }
                label="Filter"
              />
            </ThemeProvider>
            <MSelect
              shrink={true}
              fullWidth={false}
              classNameFC={`${classes.formControlSearchBy} ${classes.filter}`}
              variant="standard"
              name="searchBy"
              label="Search By"
              keyPair={['id', 'name']}
              options={renderSearchByOptions()}
              value={searchBy}
              onChange={this.handleFilterChange}
            />
            <MSelect
              shrink={true}
              fullWidth={false}
              classNameFC={`${classes.formControlStatus} ${classes.filter}`}
              variant="standard"
              name="status"
              label="Status"
              keyPair={['id', 'name']}
              options={renderMethodOptions()}
              value={status}
              onChange={this.handleFilterChange}
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="startDate"
              label="Start Date"
              value={startDate}
              onDateChange={featureFlag.datePicker.enable_datepicker ?
                (date) => handleDateChange(date) :
                this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              maxDate={featureFlag.datePicker.enable_datepicker ?
                new Date() :
                endDate ? new Date(endDate) : undefined}
              error={
                this.state.startDate === null && this.state.endDate !== null ? 'Start Date is required' :
                  startDate === null && this.state.errorFilterDate ? 'Start Date is required' :
                    undefined
              }
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="endDate"
              label="End Date"
              value={endDate}
              onDateChange={featureFlag.datePicker.enable_datepicker ?
                (date) => handleDateChange(date) :
                this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              minDate={new Date(startDate)}
              maxDate={featureFlag.datePicker.enable_datepicker ?
                new Date() :
                null}
              error={
                this.state.startDate !== null && this.state.endDate === null ? 'End Date is required' :
                  endDate === null && this.state.errorFilterDate ? 'End Date is required' :
                    undefined
              }
            />
            <div className={classes.btnFilterContainer}>
              <MButton
                className={classes.filterBtnApply}
                label="Apply"
                color="primary"
                onClick={this.handleButtonFilter}
              />
            </div>
            <div className={classes.btnFilterContainer}>
              <MButton
                className={classes.filterBtn}
                label="Reset"
                icon={<Refresh />}
                onClick={this.handleReset}
              />
            </div>
            <div className={classes.btnFilterContainer}>
              <MButton
                className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                label="Export"
                icon={<SaveAlt />}
                onClick={this.handleExport}
                loading={exportLoading}
                disabled={reimburseData?.length < 1 ? true : false}
              />
            </div>
            <br />
            {/* <Typography style={{margin: '0px 4px 0', justifyContent: 'end', display: (exportData) ? 'flex' : 'none'}}>
              or click &nbsp;
              <Link href={exportData} target="_blank" >this link</Link>
                &nbsp;
                if file corrupt
            </Typography> */}
          </>
        ) : (
          <Grid container justifyContent='space-between'>
            <Grid item>
              <MInput
                autoFocus
                className={`${classes.filter} ${classes.searchInput}`}
                fullWidth={false}
                variant="standard"
                name="query"
                label="Search"
                placeholder="Search keyword"
                value={query}
                onChange={this.handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end">
                      <Search className={classes.searchIcon} />
                    </IconButton>
                  ),
                }}
              />
              <ThemeProvider theme={this.checkboxTheme()}>
                <FormControlLabel
                  style={!this.state.filterCheckbox ?{marginTop: 22} : {marginRight: 24, marginTop: 22}}
                  control={
                    <Checkbox
                      color='primary'
                      checked={this.state.filterCheckbox}
                      onChange={(e) => this.handleFilterCheckbox(e)}
                    />
                  }
                  label="Filter"
                />
              </ThemeProvider>
            </Grid>
            <Grid item>
              <div className={classes.btnFilterContainer}>
                <MButton
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  style={{marginTop: 22}}
                  label="Export"
                  icon={<SaveAlt/>}
                  onClick={this.handleExport}
                  disabled={reimburseData?.length < 1 ? true : false}
                  loading={exportLoading}
                />
                <br />
              </div>
              {/* <Typography style={{margin: '0px 4px 0', justifyContent: 'end', display: (exportData) ? 'flex' : 'none'}}>
              or click &nbsp;
                <Link href={exportData} target="_blank" >this link</Link>
                &nbsp;
                if file corrupt
              </Typography> */}
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  };

  fetchReimburse = () => {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      method,
      status,
      searchBy,
    } = this.state;
    this.props.getReimburseHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(status && status !== 'all' && {status}),
      ...(searchBy && searchBy !== 'all' && {searchBy}),
    });
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchReimburse());
  };

  toggleModal = (type, data) => {
    const {reimburseData} = this.props;
    if (type === 'detail') {
      const numberId = data.rowData[0]; // get detail attendance from datatable response, based on index
      const selected = reimburseData.filter((data) => data.no === numberId);
      const detailData = selected && selected.length ? selected[0] : null;
      this.setState({
        showDetail: true,
        errorMessage: null,
        reimburs_id: detailData.id,
        formData: {
          name: detailData.name,
          nik: detailData.nik,
          job_title: detailData.job_title,
          submit_date: detailData.submit_date,
          amount: detailData.amount,
          date: detailData.date,
          account_no: detailData.account_no,
          notes: detailData.rejected_reason,
          details: detailData.details,
          image: detailData.image,
          hr_image: detailData.attachment,
          status: detailData.status === 'waiting' ? null : detailData.status,
          rejected_reason: detailData.rejected_reason,
          user_img: detailData.images,
          approval_history: featureFlag.reimburse.enable_approval ? detailData.approval_history : '',
        },
      });
    } else if (type === 'close') {
      this.setState({
        showDetail: false,
        detailData: null,
        showReject: false,
        showApprove: false,
        formData: {
          name: null,
          nik: null,
          job_title: null,
          submit_date: null,
          amount: null,
          date: null,
          account_no: null,
          details: null,
          image: null,
          hr_image: null,
          status: '',
          rejected_reason: '',
          notes: '',
          user_img: null,
          imageURL: null,
          cropped_user_img: null,
          approval_history: featureFlag.reimburse.enable_approval ? null : '',
        },
      }, () => console.log('S'));
    }
  };

  // handleFilterDateChange = (params) => {
  //   const {value, name} = params;
  //   this.setState({date: convDate(value), page: null, sortBy: 'name'}, () => this.fetchReimburse());
  //   this.toggleResetPage();
  // };

  toggleCropperModal = (status) => {
    this.setState({isCropping: status});
  };

  handleCrop = (result) => {
    this.setState({
      formData: {
        ...this.state.formData,
        cropped_user_img: result.blob,
        imageURL: result.imageURL,
      },
    });
  };

  handleInputChange = (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;
    if (name === 'user_img') {
      const image = event.target.files[0];
      const imageSizeIsAllowed = checkFileUploadSize(image);
      if (imageSizeIsAllowed) {
        const imageURL = URL.createObjectURL(image);
        this.setState({
          isCropping: true,
          errorImageSize: null,
          errorMessage: {...errorMessage, user_img: null},
          formData: {
            ...formData,
            imageURL: featureFlag.reimburse.enable_attachment ? imageURL : '',
            user_img: image,
          },
        });
      } else {
        let message;
        if (imageSizeIsAllowed === false) {
          message =
            'Maximum file size is ' +
            process.env.REACT_APP_MAX_UPLOAD_SIZE_MB +
            ' MB';
        }
        if (imageSizeIsAllowed === null) message = 'Please select image';
        this.setState({
          errorImageSize: message,
          imageURL: null,
          user_img: null,
        });
      }
    } else {
      this.setState({
        formData: {...formData, [name]: value},
        errorMessage: {...errorMessage, [name]: null},
      });
    }
  };


  handleChangeStatus = (data, status) => {
    const {reimburseData} = this.props;
    const idUser = reimburseData.filter((value) => {
      if (featureFlag.reimburse.reimbuse_fix_action) {
        return value.id === data[1];
      } else {
        return value.name === data[2];
      }
    });
    this.setState((prevSate) => ({
      ...prevSate,
      selectedStatus: status,
      reimburs_id: idUser[0].id,
      formData: {
        ...prevSate.formData,
        status: status,
        rejected_reason: '',
      },
    }), () => {
      if (status === 'approved') {
        this.setState({showApprove: true});
      } else {
        this.setState({showReject: true});
      }
    });
  }


  changeReimburseStatus = (id) => {
    const {formData, showDetail, showReject, selectedStatus, cropped_user_img} = this.state;
    const {status, rejected_reason, total_days, start_date, end_date, approve, details, notes} = formData;
    const errors = {};
    const params = {};

    const dataForm = new FormData();

    if (rejected_reason === null || rejected_reason === '') {
      return this.setState({
        errorMessage: {
          rejected_reason: 'Notes is required',
        },
      });
    } else {
      if (status) dataForm.append('status', status);
      if (rejected_reason) dataForm.append('rejected_reason', rejected_reason);
      if (cropped_user_img) {
        dataForm.append('reimburse_img', blobToFile(cropped_user_img));
      }
      this.props.putReimbursStatus(id, params);
    }
  };

  changeReimburseStatusApprove = (id) => {
    const {formData, showDetail, showReject, selectedStatus, cropped_user_img} = this.state;
    const {status, rejected_reason, total_days, start_date, end_date, approve, details, notes} = formData;
    const errors = {};
    const params = {};

    const dataForm = new FormData();

    if (rejected_reason === null || rejected_reason === '') {
      return this.setState({
        errorMessage: {
          notes: 'Notes is required',
        },
      });
    } else {
      if (status) dataForm.append('status', status);
      if (rejected_reason) dataForm.append('rejected_reason', rejected_reason);
      if (cropped_user_img) {
        dataForm.append('user_img', blobToFile(cropped_user_img));
      }
      this.props.putReimbursStatus(id, params);
    }
  }

  handleSubmitReimburse = () => {
    const {
      status,
      rejected_reason,
      total_days,
      start_date,
      end_date,
      approve,
      details,
      notes,
      cropped_user_img,
      hr_image,
    } = this.state.formData;

    const {reimburs_id} = this.state;

    const dataForm = new FormData();

    if (!status) {
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          status: 'Status is required',
        },
      });
    }

    if (!rejected_reason || !status) {
      return this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          rejected_reason: !rejected_reason ? 'Notes is required' : null,
          status: !status ? 'Status is required' : null,
        },
      });
    } else {
      if (status) dataForm.append('status', status);
      if (rejected_reason) dataForm.append('rejected_reason', rejected_reason);
      if (cropped_user_img) {
        dataForm.append('reimburse_img', blobToFile(cropped_user_img));
      }

      if (featureFlag.reimburse.enable_attachment) {
        dataForm.append('image', JSON.stringify(hr_image || []));
        if (hr_image && hr_image.length > 0) {
          hr_image.forEach((img, index) => {
            if (img.file) {
              dataForm.append(`hr_image_${index}`, img.file);
            }
          });
        }
      }
      this.props.putReimbursStatus(reimburs_id, dataForm);
    }
  }

  handleHRImageChange = (event) => {
    if (featureFlag.reimburse.enable_attachment) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.setState((prevState) => ({
            formData: {
              ...prevState.formData,
              hr_image: [...(prevState.formData.hr_image || []), {img: reader.result, file}],
            },
          }));
        };
        reader.readAsDataURL(file);
      }
    }
  };

  confirmRemoveHRAttachment = (index) => {
    if (featureFlag.reimburse.enable_attachment) {
      showPopup(
        'confirm',
        'Are you sure want to delete this image ?',
        'Yes',
        () => this.removeHRAttachment(index),
      );
    }
  };

  removeHRAttachment = (index) => {
    if (featureFlag.reimburse.enable_attachment) {
      this.setState((prevState) => {
        const updatedImages = prevState.formData.hr_image.filter((_, i) => i !== index);
        return {
          formData: {
            ...prevState.formData,
            hr_image: updatedImages,
          },
        };
      });
    }
  };

  renderHRAttachment = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 2,
      arrows: false,
    };

    const {hr_image} = this.state.formData;
    const images = hr_image && hr_image.length > 0;
    if (featureFlag.reimburse.enable_attachment) {
      return (
        <div>
          {images ? (
            hr_image.length > 3 ? (
              <Slider {...settings}>
                {hr_image.map((value, index) => (
                  <div key={index.toString()} style={{position: 'relative'}}>
                    <button
                      onClick={() => this.confirmRemoveHRAttachment(index)}
                      style={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        background: 'red',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        cursor: 'pointer',
                      }}
                    >
                      ×
                    </button>
                    <Link href={value.img} target="_blank" rel="noopener noreferrer">
                      <img
                        style={{width: '100px', height: '100px', objectFit: 'cover', padding: '4px'}}
                        src={value.img}
                        alt='hr-img'
                      />
                    </Link>
                  </div>
                ))}
              </Slider>
            ) : (
              <Grid container wrap="nowrap" style={{display: 'flex', overflowX: 'auto', gap: '8px', alignItems: 'center', justifyContent: 'center'}}>
                {hr_image.map((value, index) => (
                  <Grid item xs={4} key={index.toString()} style={{position: 'relative'}}>
                    <button
                      onClick={() => this.confirmRemoveHRAttachment(index)}
                      style={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        background: 'red',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        cursor: 'pointer',
                      }}
                    >
                      ×
                    </button>
                    <Link href={value.img} target="_blank" rel="noopener noreferrer">
                      <img
                        style={{width: '100px', height: '100px', objectFit: 'cover', padding: '4px'}}
                        src={value.img}
                        alt='hr-img'
                      />
                    </Link>
                  </Grid>
                ))}
              </Grid>
            )
          ) : (
            <div style={{textAlign: 'center'}}>
              <img src={noAttachment} style={{width: '100px', height: '100px', objectFit: 'cover', padding: '4px'}} alt='No attachment available' />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {
            hr_image ? hr_image.length > 3 ?
              <Slider {...settings}>
                {
                  hr_image ? hr_image.map((value, index) => {
                    return <div key={index.toString()}>
                      <Link href={value.img} target="_blank" rel="noopener noreferrer">
                        <img style={{
                          width: '100%',
                          padding: '8px 0px 8px 0px',
                        }} src={value.img} alt='hr-img'/>
                      </Link>
                    </div>;
                  }) : null
                }
              </Slider> : <Grid container wrap="nowrap" style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                {
                  hr_image.map((value, index) => {
                    return (
                      <Grid item xs={4} key={index.toString()} >
                        <div>
                          <Link href={value.img} target="_blank" rel="noopener noreferrer">
                            <img style={{
                              width: '100%',
                              padding: '8px 0px 8px 0px',
                            }} src={value.img} alt='hr-img'/>
                          </Link>
                        </div>
                      </Grid>);
                  })
                }
              </Grid> : null
          }
        </div>
      );
    }
  };

  renderSliderimage = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 2,
      arrows: false,
    };

    const {image} = this.state.formData;
    if (featureFlag.reimburse.enable_attachment) {
      return (
        <div>
          {
            image ? image.length > 3 ?
              <Slider {...settings}>
                {
                  image.map((value, index) => {
                    return (
                      <div key={index.toString()}>
                        <Link href={value.img} target="_blank" rel="noopener noreferrer">
                          <img style={{
                            width: '150px',
                            height: '150px',
                            objectFit: 'cover',
                            padding: '8px 0px 8px 0px',
                          }} src={value.img} alt='hr-img' />
                        </Link>
                      </div>
                    );
                  })
                }
              </Slider> :
              <Grid container wrap="nowrap" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                {
                  image.map((value, index) => {
                    return (
                      <Grid item xs={4} key={index.toString()} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                        <div>
                          <Link href={value.img} target="_blank" rel="noopener noreferrer">
                            <img style={{
                              width: '100px',
                              height: '100px',
                              objectFit: 'cover',
                              padding: '8px 0px 8px 0px',
                            }} src={value.img} alt='hr-img' />
                          </Link>
                        </div>
                      </Grid>
                    );
                  })
                }
              </Grid> : null
          }
        </div>
      );
    } else {
      return (
        <div>
          {
            image ? image.length > 3 ?
              <Slider {...settings}>
                {
                  image ? image.map((value, index) => {
                    return <div key={index.toString()}>
                      <Link href={value.img} target="_blank" rel="noopener noreferrer">
                        <img style={{
                          width: '100%',
                          padding: '8px 0px 8px 0px',
                        }} src={value.img} alt='hr-img' />
                      </Link>
                    </div>;
                  }) : null
                }
              </Slider> : <Grid container wrap="nowrap" style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                {
                  image.map((value, index) => {
                    return (
                      <Grid item xs={4} key={index.toString()} style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                        <div>
                          <Link href={value.img} target="_blank" rel="noopener noreferrer">
                            <img style={{
                              width: '100%',
                              padding: '8px 0px 8px 0px',
                            }} src={value.img} alt='hr-img' />
                          </Link>
                        </div>
                      </Grid>);
                  })
                }
              </Grid> : null
          }
        </div>
      );
    }
  }

  setAmount = (amount) => {
    const value = amount ? amount.replace(/(?!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.') : 0;
    return value;
  }

  toggleTimelineAccordion = () => {
    if (featureFlag.reimburse.enable_approval) {
      this.setState({
        timelineAccordion: !this.state.timelineAccordion,
      });
    }
  }

  renderModalDetail = () => {
    const {classes, isLoadingPut, reimburseData} = this.props;
    const {showDetail, formData, errorMessage, errorImageSize, showCropResult, isCropping, timelineAccordion} = this.state;
    const nominal = formData.amount;
    const {status, user_img, imageURL} = this.state.formData;

    const hr_image = this.state.formData?.hr_image || [];

    const renderStatusOption = () => {
      const options = [
        {id: 'approved', name: 'Approved'},
        {id: 'rejected', name: 'Rejected'},
      ];
      return options;
    };

    const reversed = formData?.approval_history ? formData.approval_history.reverse() : [];

    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Dialog
          open={showDetail}
          onClose={() => this.toggleModal('close')}
          maxWidth="md"
          fullWidth={true}
          scroll="paper"
        >
          <ModalTitle
            className={classes.modalTitle}
            title={<div style={{color: '#656464 '}}>Reimburse Detail</div>}
            onClick={() => this.toggleModal('close')}
            style={{
              borderBottom: '1px solid #E0E0E0',
            }}
          />
          <DialogContent className={classes.dialogContent}>
            {featureFlag.reimburse.enable_approval && (
              <div style={{marginBottom: 24}}>
                <ThemeProvider theme={this.accordionTheme()}>
                  <Accordion expanded={timelineAccordion} onChange={this.toggleTimelineAccordion} variant='outlined'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography className={classes.accordionTitle}>
                        Status
                      </Typography>
                      <Typography
                        className={status === 'request' ? classes.statusRequest :
                          status === 'on_going' ? classes.statusOngoing :
                            status === 'rejected' ? classes.statusRejected :
                              status === 'approved' ? classes.statusCompleted : null}
                      >
                        {status === 'request' ? 'Request' :
                          status === 'on_going' ? 'On Going' :
                            status === 'rejected' ? 'Rejected' :
                              status === 'approved' ? 'Approved' : null}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Divider style={{marginBottom: 8}} />
                      <ThemeProvider theme={this.timelineTheme()}>
                        <Timeline
                          sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                              flex: 0,
                              padding: 0,
                            },
                          }}
                        >
                          {reversed?.map((data, index) => {
                            return (
                              <TimelineItem key={index.toString()}>
                                <TimelineSeparator>
                                  <TimelineDot color={index === reversed?.length - 1 && data.status === 'request' ? 'primary' :
                                    index === reversed?.length - 1 && data.status === 'on_going' ? 'secondary' :
                                      index === reversed?.length - 1 && data.status === 'rejected' ? 'warning' :
                                        index === reversed?.length - 1 && data.status === 'approved' ? 'success' : 'grey'} />
                                  {index === reversed?.length - 1 ? (null) : (<TimelineConnector />)}
                                </TimelineSeparator>
                                <TimelineContent>
                                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <div>
                                      <Typography
                                        className={data.status === 'request' ? classes.statusRequest :
                                          data.status === 'on_going' ? classes.statusOngoing :
                                            data.status === 'rejected' ? classes.statusRejected :
                                              data.status === 'approved' ? classes.statusCompleted : null}
                                      >
                                        {data.status === 'request' ? 'Request' :
                                          data.status === 'on_going' ? 'On Going' :
                                            data.status === 'rejected' ? 'Rejected' :
                                              data.status === 'approved' ? 'Approved' : null}
                                      </Typography>
                                      {data.status === 'approved' ? (
                                        <Typography className={classes.timelineDate}>
                                          Approved by: {data.name}
                                        </Typography>
                                      ) : data.status === 'rejected' ? (
                                        <Typography className={classes.timelineDate}>
                                          Rejected by: {data.name}
                                        </Typography>
                                      ) : null}
                                      <Typography
                                        className={classes.timelineDate}
                                      >
                                        {data.status === 'rejected' ? formData?.rejected_reason : null}
                                      </Typography>
                                    </div>
                                    <Typography className={classes.timelineDate}>{data?.approved_date ?? null}</Typography>
                                  </div>
                                </TimelineContent>
                              </TimelineItem>
                            );
                          })}
                        </Timeline>
                      </ThemeProvider>
                    </AccordionDetails>
                  </Accordion>
                </ThemeProvider>
              </div>
            )}
            <Grid container wrap="nowrap" spacing={2} style={{marginLeft: 4}}>
              <Grid container xs={6}>
                <Grid item xs={3}>
                  <div>
                    <p>Name</p>
                    <p>NIK</p>
                    <p>Job Title</p>
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div>
                    <p> : {formData.name} </p>
                    <p> : {formData.nik} </p>
                    <p> : {formData.job_title} </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs={6}>
                <MDatepicker
                  className={classes.formControl}
                  name="submission_date"
                  label="Reimburse Date"
                  value={formData.submit_date}
                  placeholder="dd-mm-yyyy"
                  inputVariant="outlined"
                  views={['year', 'month', 'date']}
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <NumericFormat
                  className={classes.formControl}
                  fullWidth
                  thousandSeparator='.'
                  color='primary'
                  decimalSeparator=','
                  prefix='Rp.'
                  label='Amount'
                  customInput={TextField}
                  value={nominal}
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              {/* <Grid item xs={6}>
                <MDatepicker
                  className={classes.formControl}
                  name="reimburse_date"
                  label="Reimburse Date"
                  value={formData.date}
                  placeholder="dd-mm-yyyy"
                  inputVariant="outlined"
                  views={['year', 'month', 'date']}
                  disabled={true}
                  fullWidth
                />
              </Grid> */}
              <Grid item xs={6}>
                <MInput
                  classNameFC={classes.formControl}
                  name="reimburse_statement"
                  multiline
                  type="text"
                  label="Reimburse Statement"
                  placeholder="Reimburse Statement"
                  value={formData.details}
                  onBlur={this.handleInputChange}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <MInput
                  classNameFC={classes.formControl}
                  name="account_number"
                  type="text"
                  label="Account Number"
                  placeholder="Account Number"
                  defaultValue={formData.account_no}
                  onBlur={this.handleInputChange}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs={6}>
                <MInput
                  classNameFC={classes.formControl}
                  name="rejected_reason"
                  type="text"
                  label="Notes *"
                  placeholder="Enter Notes"
                  defaultValue={formData.rejected_reason}
                  onBlur={this.handleInputChange}
                  // disabled={true}
                  error={
                    errorMessage && errorMessage.rejected_reason ?
                      errorMessage.rejected_reason :
                      undefined
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <MSelect
                  classNameFC={classes.formControl}
                  name="status"
                  label="Status *"
                  value={status}
                  onChange={this.handleInputChange}
                  error={
                    errorMessage && errorMessage.status ?
                      errorMessage.status :
                      undefined
                  }
                  keyPair={['id', 'name']}
                  options={renderStatusOption()}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='center'>
              <Grid item xs={12} style={{marginTop: '10px'}}>
                <>
                  {formData.imageURL && featureFlag.reimburse.enable_attachment &&(
                    <div>
                      <img
                        src={formData.imageURL}
                        alt="Preview"
                        style={{
                          maxWidth: '200px',
                          maxHeight: '200px',
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  )}
                  {featureFlag.reimburse.enable_attachment && (this.state.formData.hr_image?.length || 0) >= 3 ? (
                    <p style={{color: 'red', textAlign: 'center', marginTop: '10px'}}>
                      You Already added 3 Attachment!
                    </p>
                  ) : (
                    <><MInput
                        classNameFC={classes.formControl}
                        name="user_img"
                        label="HR Attachment"
                        placeholder="Photo"
                        type="file"
                        onChange={this.handleInputChange}
                        inputProps={{
                          accept: featureFlag.reimburse.enable_attachment ?
                            'image/jpg, image/jpeg, image/png' :
                            'image/jpg, image/jpeg',
                        }}
                        error={errorImageSize ?
                          errorImageSize :
                          errorMessage && errorMessage.user_img ?
                            errorMessage.user_img :
                            undefined} /><span><i>Must be .jpg/.jpeg/.png with recommended resolution 200 x 200 px</i></span></>
                  )}

                  {!isCropping && imageURL && (
                    <div>
                      <CardMedia
                        className={classes.photo}
                        title="Photo"
                        image={imageURL}
                      />
                      {user_img && (
                        <MButton
                          className={`${classes.defaultBtn} ${classes.btnCrop}`}
                          label="Retry"
                          icon={<Crop />}
                          onClick={() => this.toggleCropperModal(true)}
                        />
                      )}
                    </div>
                  )}
                  <ImageCropper
                    showModal={isCropping}
                    src={user_img}
                    showResult={showCropResult}
                    onClose={() => this.toggleCropperModal(false)}
                    onComplete={this.handleCrop}
                  />
                </>
              </Grid>
            </Grid>
            {featureFlag.reimburse.enable_attachment ? (
              <Grid container justifyContent="center" spacing={3} style={{marginTop: '10px'}}>
                <Grid item xs={6} style={{marginTop: '10px'}}>
                <div style={{position: 'relative', width: '100%', display: 'inline-block', padding: '10px', border: '2px solid #ccc', borderRadius: '8px', backgroundColor: '#fff'}} >
                    <p
                      style={{
                        position: 'absolute',
                        top: '-12px',
                        left: '10px',
                        backgroundColor: '#fff',
                        padding: '0 5px',
                      }}
                    >
                      User Attachment:
                    </p>
                    <Tooltip title="Klik untuk memperbesar" arrow>
                      <div onClick={this.handleImageClick} style={{cursor: 'pointer'}}>
                        {this.renderSliderimage()}
                      </div>
                    </Tooltip>
                  </div>
                </Grid>

                <Grid item xs={6} style={{marginTop: '10px'}}>
                <div style={{position: 'relative', width: '100%', display: 'inline-block', padding: '10px', border: '2px solid #ccc', borderRadius: '8px', backgroundColor: '#fff'}} >
                    <div
                      style={{
                        position: 'absolute',
                        top: '-12px',
                        left: '10px',
                        backgroundColor: '#fff',
                        padding: '0 5px',
                      }}
                    >
                      <Tooltip title="Maximum 3 Attachments only" arrow>
                        <span style={{textAlign: 'center', cursor: 'pointer'}} className="mb-0">
                          HR Attachment
                        </span>
                      </Tooltip>
                    </div>

                    <div onClick={this.handleImageClick} style={{cursor: 'pointer'}}>
                      <Tooltip title={hr_image.length > 0 ? 'Klik untuk memperbesar' : ''} arrow>
                        {this.renderHRAttachment()}
                      </Tooltip>
                    </div>
                </div>
                </Grid>
              </Grid>
            ) : (
              <Grid container justifyContent="center" spacing={3} style={{marginTop: '10px'}}>
                <Grid item xs={6} style={{marginTop: '10px'}}>
                  <p style={{textAlign: 'start'}} className="mb-4">User Attachment: </p>
                  {this.renderSliderimage()}
                </Grid>
                <Grid item xs={6} style={{marginTop: '10px'}}>
                  <p style={{textAlign: 'start'}} className="mb-4">HR Attachment: </p>
                  {this.renderHRAttachment()}
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <MButton className={classes.cancelBtn} label="Cancel" onClick={() => this.toggleModal('close')} />
            <MButton
              className={classes.defaultBtnReject}
              label="Update"
              icon={<Send />}
              loading={isLoadingPut}
              onClick={() => this.handleSubmitReimburse()}
            />
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  };

  renderTableTitle = () => {
    const {classes} = this.props;
    return (
      <div className={classes.MUIDataTableToolbar}>
        <div className="d-flex">
          <Typography className={classes.tableTitle}>Reimburse</Typography>
          <ThemeProvider theme={this.tooltipTheme()}>
            <Tooltip title="Click Filter to see all data from reimburse list">
              <IconButton className={classes.tableTooltip}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </ThemeProvider>
          {/* <span className="">Remaining Quota: {remaining_quota}</span>   */}
        </div>
      </div>
    );
  };


  renderModalRejected = () => {
    const {classes, isLoadingPut} = this.props;
    const {showReject, detailData, errorMessage, reimburs_id} = this.state;
    const rejected_reason =
      detailData && detailData.rejected_reason ?
        detailData.rejected_reason :
        '';
    const id = detailData && detailData.id ? detailData.id : null;
    return (
      <Dialog
        open={showReject}
        onClose={() => this.toggleModal('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title={<div style={{color: '#656464'}}>Reimburse Reject</div>}
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.rejectDialogContent}>
          <MInput
            classNameFC={classes.formControl}
            multiline
            rows={2}
            rowsMax={3}
            name="rejected_reason"
            label="Notes *"
            placeholder="Fill in notes"
            defaultValue={rejected_reason}
            onBlur={this.handleInputChange}
            error={
              errorMessage && errorMessage.rejected_reason ?
                errorMessage.rejected_reason :
                undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <MButton className={classes.cancelBtn} label="Cancel" onClick={() => this.toggleModal('close')} />
          <MButton
            className={classes.defaultBtnReject}
            label="Reject"
            icon={<Send />}
            loading={isLoadingPut}
            onClick={() => this.handleSubmitReimburse()}
          />
        </DialogActions>
      </Dialog>
    );
  };
  renderModalApproved = () => {
    const {classes, isLoadingPut} = this.props;
    const {showApprove, detailData, errorMessage, reimburs_id, errorImageSize,
      showCropResult,
      isCropping, formData} = this.state;
    const {user_img, imageURL} = this.state.formData;
    const rejected_reason =
      detailData && detailData.rejected_reason ?
        detailData.rejected_reason :
        '';
    const id = detailData && detailData.id ? detailData.id : null;
    return (
      <Dialog
        open={showApprove}
        onClose={() => this.toggleModal('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title={<div style={{color: '#656464'}}>Reimburse Approve</div>}
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.rejectDialogContent}>
          <MInput
            classNameFC={classes.formControl}
            multiline
            rows={2}
            rowsMax={3}
            name="rejected_reason"
            label="Notes *"
            placeholder="Fill in notes"
            defaultValue={rejected_reason}
            onBlur={this.handleInputChange}
            error={
              errorMessage && errorMessage.rejected_reason ?
                errorMessage.rejected_reason :
                undefined
            }
          />
          <>
            {formData.imageURL && featureFlag.reimburse.enable_attachment &&(
              <div>
                <img
                  src={formData.imageURL}
                  alt="Preview"
                  style={{
                    maxWidth: '200px',
                    maxHeight: '200px',
                    objectFit: 'cover',
                  }}
                />
              </div>
            )}
            <MInput
              classNameFC={classes.formControl}
              name="user_img"
              label="Photo"
              placeholder="Photo"
              type="file"
              onChange={this.handleInputChange}
              inputProps={{accept: 'image/*'}}
              error={
                errorImageSize ?
                  errorImageSize :
                  errorMessage && errorMessage.user_img ?
                    errorMessage.user_img :
                    undefined
              }
            />
            {featureFlag.reimburse.enable_attachment ? (
              <span><i>Must be .jpg/.jpeg/.png with recommended resolution 200 x 200 px</i></span>
            ) : (
              <span><i>Must be .jpg/.jpeg with recommended resolution 200 x 200 px</i></span>
            )}
            {!isCropping && imageURL && (
              <div>
                <CardMedia
                  className={classes.photo}
                  title="Photo"
                  image={imageURL}
                />
                {user_img && (
                  <MButton
                    className={`${classes.defaultBtn} ${classes.btnCrop}`}
                    label="Retry"
                    icon={<Crop />}
                    onClick={() => this.toggleCropperModal(true)}
                  />
                )}
              </div>
            )}
            <ImageCropper
              showModal={isCropping}
              src={user_img}
              showResult={showCropResult}
              onClose={() => this.toggleCropperModal(false)}
              onComplete={this.handleCrop}
            />
          </>
        </DialogContent>
        <DialogActions>
          <MButton className={classes.cancelBtn} label="Cancel" onClick={() => this.toggleModal('close')} />
          <MButton
            className={classes.defaultBtnReject}
            label="Approve"
            icon={<Send />}
            loading={isLoadingPut}
            onClick={() => this.handleSubmitReimburse()}
          />
        </DialogActions>
      </Dialog>
    );
  };

  renderActions = (params) => {
    return (
      <div style={{whiteSpace: 'nowrap'}}>
        {featureFlag.reimburse.enable_actions ? (
          params[7] && params[7] === 'waiting' ? (
          <>
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Tooltip title="Approved">
                <Button
                  variant="contained"
                  // color="primary"
                  style={{
                    'backgroundColor': '#008000',
                    'boxShadow': 'none',
                    'color': '#fff',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#007000',
                    },
                    'minWidth': '10px',
                    'paddingRight': '0px',
                  }}
                  size="small"
                  onClick={() => this.handleChangeStatus(params, 'approved')}
                  startIcon={<CheckCircle />}
                >
                </Button>
              </Tooltip>
            </div>
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Tooltip title="Rejected">
                <Button
                  variant="contained"
                  // color="primary"
                  size="small"
                  style={{
                    'backgroundColor': '#E5AF5A',
                    'boxShadow': 'none',
                    'color': '#fff',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#D09F53',
                    },
                    'minWidth': '10px',
                    'paddingRight': '0px',
                  }}
                  onClick={() => this.handleChangeStatus(params, 'rejected')}
                  startIcon={<Cancel />}
                >
                </Button>
              </Tooltip>
            </div>
          </>
          ) : null
        ) : params[6] && params[6] === 'waiting'
      }
      </div>
    );
  };

  render() {
    const {classes, reimburseData, reimburseMeta, isLoading} = this.props;
    const {resetDatatable, resetPage} = this.state;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {name: 'id', label: 'id', display: false},
      {
        name: 'name',
        label: 'Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'job_title',
        label: 'Job Title',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'submit_date',
        label: 'Reimburse Date',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {
        name: 'approve',
        label: 'PIC',
        display: false,
      },
      {
        name: 'date',
        label: 'Reimburse Date',
        display: false,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          if (value === 'approved') {
            return <strong style={{textTransform: 'capitalize'}} className="text-success">{value}</strong>;
          } else if (value === 'rejected') {
            return <strong style={{textTransform: 'capitalize'}} className="text-danger">{value}</strong>;
          } else if (value === 'waiting') {
            return <strong style={{textTransform: 'capitalize'}} className="text-warning">{value}</strong>;
          } else {
            return '-';
          }
        },
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          creatable={false}
          title={this.renderTableTitle()}
          loading={isLoading}
          dataSource={reimburseData}
          total={reimburseMeta.total}
          page={reimburseMeta.page}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          // handleDetail={(params) => this.toggleModal('detail', params)}
          customActions={(params) => this.renderActions(params)}
          handleDetail={(params) => this.toggleModal('detail', params)}
          // customActions={(params) => console.log('params')}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderSnackbar()}
        {this.renderModalDetail()}
        {this.renderModalRejected()}
        {this.renderModalApproved()}
      </div>
    );
  }
}

const mapStateToProps = ({reimburse}) => {
  return {
    isLoading: reimburse.isLoading,
    isSuccess: reimburse.isSuccess,
    isError: reimburse.isError,
    message: reimburse.message,
    reimburseData: reimburse.reimburseData,
    reimburseMeta: reimburse.reimburseMeta,
    // detailLoading: paidLeave.detailLoading,
    // detailData: paidLeave.detailData,
    trigger: reimburse.trigger,

    isLoadingPut: reimburse.isLoadingPut,
    isSuccessPut: reimburse.isSuccessPut,
    isFailurePut: reimburse.isFailurePut,
    isErrorPut: reimburse.isErrorPut,
    messagePut: reimburse.messagePut,
    status: reimburse.status,
    exportLoading: reimburse.exportLoading,
    exportData: reimburse.exportData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReimburseHistory: (params) => dispatch(getReimburseHistory(params)),
  putReimbursStatus: (id, params) =>
    dispatch(putReimbursStatus(id, params)),
  resetReimburseStatus: () => dispatch(resetReimburseStatus()),
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),
  exportExcel: (params) => dispatch(exportExcel(params)),
  updateExportNotification: (params) => dispatch(updateExportNotification(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Reimburse));
