/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { toast } from '../../helpers';
import {BLASTING_NOTIF} from '../../services';
import {BLASTING_NOTIF_STATE as STATE} from './blastingNotif.types';

export const allBlastingNotif = (params) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH));
    BLASTING_NOTIF.allBlastingNotif(params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.FETCH_SUCCCESS, response.data.data));
        },
      (error) => {
        const response = error.response.data;
        dispatch(requestFailed(STATE.FETCH_FAILED, response.data));
      }
    );
  };
};

export const detailBlastingNotif = (id) => {
  return (dispatch) => {
    dispatch(request(STATE.FETCH_BY_ID, true));
    BLASTING_NOTIF.detailBlastingNotif(id)
        .then(
            (response) => {
              dispatch(requestSuccess(STATE.FETCH_BY_ID_SUCCCESS, response.data));
            },
            (error) => {
              const response = error.response.data;
              dispatch(requestFailed(STATE.FETCH_BY_ID_FAILED, response.data.message));
            },
        );
  };
};

export const createBlastingNotif = (params) => {
  return (dispatch) => {
    dispatch((request(STATE.CREATE)));
    BLASTING_NOTIF.createBlastingNorif(params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.CREATE_SUCCCESS, response.data));
          toast('success', 'Blasting notification created successfully');
        },
        (error) => {
          const response = error.response.data;
          dispatch(requestFailed(STATE.CREATE_FAILED, response.data));
        }
      );
  };
};

export const updateBlastingNotif = (id, params) => {
  return (dispatch) => {
    dispatch((request(STATE.UPDATE)));
    BLASTING_NOTIF.updateBlastingNotif(id, params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.UPDATE_SUCCCESS, response.data));
          toast('success', 'Blasting notification updated successfully');
        },
        (error) => {
          const response = error.response.data;
          dispatch(requestFailed(STATE.UPDATE_FAILED, response.data));
        }
      );
  };
};

export const usersBLastingNotif = (params) => {
  return (dispatch) => {
    dispatch((request(STATE.FETCH_USERS)));
    BLASTING_NOTIF.usersBlastingNotif(params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.FETCH_USERS_SUCCCESS, response.data));
        },
        (error) => {
          const response = error.response.data;
          dispatch(requestFailed(STATE.FETCH_USERS_FAILED, response.data));
        }
      );
  }
}

export const organizationsBLastingNotif = (params) => {
  return (dispatch) => {
    dispatch((request(STATE.FETCH_ORGANIZATIONS)));
    BLASTING_NOTIF.organizationsBlastingNotif(params)
      .then(
        (response) => {
          dispatch(requestSuccess(STATE.FETCH_ORGANIZATIONS_SUCCCESS, response.data));
        },
        (error) => {
          const response = error.response.data;
          dispatch(requestFailed(STATE.FETCH_ORGANIZATIONS_FAILED, response.data));
        }
      );
  }
}

export const clearState = () => {
  return (dispatch) => dispatch(resetState());
};

const request = (requestType) => ({
  type: requestType,
});

const requestFailed = (requestType, response) => ({
  type: STATE.REQUEST_FAILED,
  requestType,
  payload: response,
});

const requestSuccess = (requestType, response) => ({
  type: requestType,
  payload: response,
});

const resetState = () => ({
  type: STATE.CLEAR_STATE,
});
