import React, { useEffect, useRef, useState } from 'react';

const AutocompleteMultiselect = ({
  name = "",
  label = "",
  options = [],
  api,
  getOptionLabel = "name",
  getOptionValue = "id",
  scrollToNextPage,
  onInputChange,
  onChange,
  value,
  error,
  ...props
}) => {
  const dropdownRef = useRef();
  const inputRef = useRef();
  
  const [inputValue, setInputValue] = useState('');
  const [page] = useState(1);
  const [limit] = useState(10);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [, setCurrentPage] = useState(1);
  const [isLoading] = useState(false);
  const [isHover,setIsHover] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (value && value.length > 0) {
      setSelectedOptions(value)
    }
  }, [value]);

  useEffect(() => {
    if (selectedOptions.length > 0) {
      typeof onChange === "function" && onChange(selectedOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const onClickDropdown = () => {
    setIsFocus(true)
    setIsHover(false)
    setIsDropdownOpen(true);
    inputRef.current.focus()
  }

  const addOption = (option) => {
    setSelectedOptions([...selectedOptions, option]);
    setInputValue('');
    inputRef.current.focus();
  };

  const removeOption = (option) => {
    setSelectedOptions(state => state.filter((item) => item.id !== option.id));
  };

  const handleScroll = (e) => {
    if (
      e.target &&
      Math.round(e.target.scrollTop + e.target.clientHeight) >= e.target.scrollHeight
    ) {
      setCurrentPage((prevPage) => {
        scrollToNextPage(prevPage + 1)
        return prevPage + 1
      });
    }
  };

  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  useEffect(() => {
    const handleClickOutside = (evt) => {
      if (dropdownRef.current && !dropdownRef.current.contains(evt.target)) {
        setIsDropdownOpen(false);
        setIsFocus(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const filteredOptions = dropdownOptions.filter(
    (option) => {
      return option[[getOptionLabel]]
    }
  );

  let borderColor = 'rgba(0,0,0,0.23)'
  if (error && selectedOptions.length < 1) borderColor = 'red';
  if (isFocus && !error) borderColor = '#00AFF0';
  if (isHover && !isFocus && !error) borderColor = 'black';

  const dropdownStyle = {}
  dropdownStyle["display"] = "flex";
  dropdownStyle["padding"] = "12.5px 9px";
  dropdownStyle["flexWrap"] = "wrap";
  dropdownStyle["gap"] = 4;
  dropdownStyle["border"] = `1px solid ${borderColor}`;
  if (isFocus) dropdownStyle["box-shadow"] = "0 0 0.6px 0.6px #00AFF0";

  const labelClass = `
    MuiFormLabel-root
    MuiInputLabel-root
    MuiInputLabel-formControl
    MuiInputLabel-animated
    MuiInputLabel-shrink
    MuiInputLabel-outlined
    MuiFormLabel-filled
    ${
      isFocus && !error ?
      'Mui-focused' : error && selectedOptions.length < 1 ?
      'Mui-error' : ''
    }
  `;

  return (
    <div ref={dropdownRef} style={{ position: 'relative' }}>
      <div className="MuiFormControl-root BlastingNotif-formControl-304 MuiFormControl-fullWidth" onClick={() => onClickDropdown()}>
        <label className={labelClass} data-shrink="true" style={{backgroundColor: "#fff", padding: "0 6px"}}>{label}</label>
        <div
          className="MuiInputBase-root MuiOutlinedInput-root MuiAutocomplete-inputRoot MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiOutlinedInput-adornedEnd"
          style={dropdownStyle}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {selectedOptions.map((option, key) => (
            <div
              key={key}
              class="MuiButtonBase-root MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-deletable MuiChip-deletableColorDefault MuiChip-filledDefault MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium css-38raov-MuiButtonBase-root-MuiChip-root"
              tabindex="-1"
              role="button"
              data-tag-index="0"
              style={{borderRadius: 50, border: "1px solid lightblue", backgroundColor: "lightblue", padding: "4px 8px"}}
              onClick={() => removeOption(option)} 
            >
              <span class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label" style={{fontSize: 14, marginRight: 4}}>{option ? option.name ? option.name : "" : ""}</span>
              <svg
                class="MuiSvgIcon-fontSizeMedium MuiChip-deleteIcon MuiChip-deleteIconMedium MuiChip-deleteIconColorDefault MuiChip-deleteIconFilledColorDefault css-i4bv87-MuiSvgIcon-root" 
                focusable="false" 
                aria-hidden="true" 
                viewBox="0 0 24 24" 
                data-testid="CancelIcon" 
                width="14" 
                height="14" 
                fill="#17a2b8">
                <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
              </svg>
            </div>
          ))}
          <input
            {...props}
            ref={inputRef}
            aria-invalid="false"
            autocomplete="off"
            id="user_ids"
            name={name}
            value={inputValue}
            onChange={(e) => {
              const search = e.target.value
              setInputValue(search)
              onInputChange({
                page, search, limit
              })
            }}
            type="text"
            className="MuiInputBase-input MuiOutlinedInput-input MuiAutocomplete-input MuiAutocomplete-inputFocused MuiInputBase-inputAdornedStart MuiOutlinedInput-inputAdornedStart MuiInputBase-inputAdornedEnd MuiOutlinedInput-inputAdornedEnd"
            style={{padding: 6, width: "fit-content"}}
            aria-autocomplete="list"
            autocapitalize="none"
            spellcheck="false"
          />
          {isDropdownOpen ? <svg className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconOpen" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M7 10l5 5 5-5z"></path>
          </svg> : <svg class="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M7 10l5 5 5-5z"></path>
          </svg>}
        </div>
        {error && selectedOptions.length < 1 && (
          <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{error}</p>
        )}
      </div>

      {isDropdownOpen && (
        <div class="MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded" tabindex="-1" style={{
          position: 'absolute',
          top: '100%',
          width: '100%',
          maxWidth: 'unset',
          maxHeight: '150px',
          overflowY: 'auto',
          overflowX:'hidden',
          zIndex: 1000,
        }} onScroll={handleScroll}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, key) => (
              <div
                key={key}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #f0f0f0',
                  background: selectedOptions.findIndex(b => option.id === b.id) !== -1 ? "lightblue" : "white"
                }}
                onClick={() => selectedOptions.findIndex(b => option.id === b.id) !== -1 ? removeOption(option) : addOption(option)}
              >
                {option ? option.name ? option.name : "" : ""}
              </div>
            ))
          ) : (
            <div
              style={{
                padding: '8px',
                color: '#999',
              }}
            >
              No options found
            </div>
          )}
          {isLoading && (
            <div
              style={{
                padding: '8px',
                textAlign: 'center',
                color: '#999',
              }}
            >
              Loading...
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AutocompleteMultiselect;