/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable no-dupe-class-members */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {connect} from 'react-redux';
import {withStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Link,
} from 'react-router-dom';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import {
  Dialog,
  DialogActions,
  DialogContent,
  CardMedia,
  Grid,
  Button,
  Divider,
  TextField,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Send,
  Search,
  Refresh,
  Visibility,
  VisibilityOff,
  PeopleAltOutlined,
  Input,
  ContactSupportOutlined,
  SaveAlt,
  ExpandMore,
  Crop,
  MoreVert,
} from '@material-ui/icons';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import {ORGANIZATION_STATE as STATE} from '../../redux/organization/organization.types';
import {INVOICE_STATE as STATE_INVOICE} from '../../redux/invoice/invoice.types';
import {REGISTER_STATE} from '../../redux/register/register.types';
import {login, setRedirectURL} from './../../redux/login/login.actions';
import {
  toast,
  showPopup,
  isObject,
  checkSuperadmin,
  checkFileUploadSize,
  convDate,
  formatCurrency,
  blobToFile,
} from '../../helpers';
import {
  MInput,
  MInputCurrency,
  MSelect,
  MButton,
  ModalTitle,
  MCheckboxGroup,
  MCheckboxItem,
  MDatepicker,
  MAutoComplete,
  MSelectSearch,
} from '../../components/form';
import InvitationOrganizationModal from '../../components/modals/invitationModal';
import {Datatable} from '../../components';
import styles from './styles';
import {apiService} from '../../config';
import {
  getAllOrganization,
  getChildOrganizationForHR,
  getAdmins,
  getOrganizationType,
  createOrganization,
  createChildOrganization,
  updateOrganization,
  deleteOrganization,
  deleteChildOrganization,
  invitationOrganization,
  clearState,
  checkOdoo,
  exportExcel,
  assignOrganizationHR,
} from '../../redux/organization/organization.actions';
import {checkStatus} from '../../redux/userManagement/userManagement.actions';
import {
  getInvoice,
  generateInvoice,
  clearStateGenerate,
  updateInvoiceStatus,
} from '../../redux/invoice/invoice.actions.js';
import {submitApproval} from '../../redux/register/register.actions';
import {Paper, IconButton, Box, Tab} from '@material-ui/core';
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import _ from 'lodash';
import {Typography} from '@material-ui/core';
import {Autocomplete} from '@mui/material';
import {
  getBankList,
} from './../../redux/bank/bank.actions';
import {
  BANK_STATE,
} from './../../redux/bank/bank.types';
import {
  getPartnerList,
} from './../../redux/partner/partner.actions';
import {
  PARTNER_STATE,
} from './../../redux/partner/partner.types';
import {
  getGeneralSetting,
} from '../../redux/generalSetting/generalSetting.actions';
import {GENERAL_SETTING_STATE} from '../../redux/generalSetting/generalSetting.types';


import ImageCropper from '../../components/cropper';
import featureFlag from '../../config/featureFlag/index.js';


class Organization extends React.Component {
  constructor() {
    super();
    this.initialForm = {
      id: null,
      name: null,
      description: null,
      address: null,
      logo: null,
      logoURL: null,
      organization_type_id: '',
      role_id: '',
      country_code: '',
      user_ids: null,
      users: [],
      pic_name: null,
      pic_number: null,
      pic_email: null,
      pic_name_invoice: null,
      pic_number_invoice: null,
      price: null,
      invoice: null,
      remark: null,
      partner_name: null,
      partner_id: null,
      feature: {
        remote: false,
        office: false,
        business: false,
        overtime: false,
        paidleave: false,
        advantech: false,
        payslip: false,
        tenant: false,
        approval: false,
        multiple_hr: false,
        checkout_business_trip: false,
        pph23: false,
        // employee_facilities: false,
      },
      status: null,
      business_limit: null,
      hr_limit: null,
      latitude: null,
      longitude: null,
      radius: null,
      user_limit: null,
      start_period: null,
      end_period: null,
      due_date: null,
      invoice_date: null,
      amount: 0,
      expired_date: null,
      organization_id: null,
      organization_name: null,
      organization_address: null,
      parent_name: null,
      adv_key: null,
      adv_value: null,
      adv_url: null,
      location: [],
      adv_url_local: null,
      odoo_url: null,
      odoo_key: null,
      payslip_limit: null,
      odooStatus: null,
      odooMessage: null,
      prevOdooUrl: null,
      prevOdooKey: null,
      fossBillingStatus: null,
      npwp_id: null,
      npwp_holder_name: null,
    };

    this.state = {
      errorImageSize: null,
      isCropping: false,
      showCropResult: false,

      formData: this.initialForm,
      userFeature: null,
      isEdit: false,
      showInvitationModal: false,
      modalFormShow: false,
      modalGenerateShow: false,
      showPassword: false,
      searchFilter: null,
      errorMessage: null,
      errorImageSize: null,
      showBusinessLimit: false,
      showMultipleHRLimit: false,
      page: 1,
      limit: 10,
      order: 'desc',
      fieldState: false,
      statusFilter: 'all',
      showPassword: false,
      locationsTab: 0,
      featuresTab: 0,
      sortBy: null,
      bankList: [],
      partnerList: [],
      startDate: null,
      endDate: null,
      no_activity: false,
      resetDatatable: null,
      anchorElBadge: null,
      anchorEl: null,
      menuParams: null,
      formInvoice: {
        id: null,
        company_name: null,
        bank_name: null,
        payment_date: null,
        amount: null,
        partner: null,
        plan: null,
        payment_method: null,
        payment_status: null,
        user_img: null,
        imageURL: null,
      },
    };
  }

  UNSAFE_componentWillMount() {
    this.redirectDashboardIfChild();
    this.props.getPartnerList();
    this.props.getOrganizationType();
    this.props.getAdmins();
    this.props.getInvoice();
    this.fetchStatusUser();
    this.props.getBankList();
    this.props.getPartnerList();

    const state = {
      page: 1,
      limit: 10,
      searchFilter: '',
      order: 'desc',
      statusFilter: 'all',
      startDate: null,
      endDate: null,
      sortBy: null,
    };
    this.setState(state, () => this.fetchOrganization());
  }

  componentDidMount() {
    this.props.getPartnerList();
    this.props.getOrganizationType();
  }

  getMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiTypography: {
        root: {
          color: '#656464',
        },
        h6: {
          fontSize: 20,
          fontWeight: '600',
          textalign: 'start',
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: 0,
        },
      },
    },
  })


  badgeTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiMenu: {
        paper: {
          boxShadow: '0px 3px 6px -3px rgba(0,0,0,0.2), 0px -11px 6px 1px rgba(0,0,0,0), 0px 3px 4px 2px rgba(0,0,0,0)',
        },
      },
    },
  })

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      trigger,
      triggerInvoice,
      isSuccess,
      isError,
      message,
      messageInvoice,
      isErrorInvoice,
      isSuccessInvoice,
      registerTrigger,
      registerMessage,
      exportData,

      isSuccessBank, triggerBank, bankData, isSuccessPartner, partnerData, triggerPartner,
    } = nextProps;

    const {
      isSuccessGeneralSetting,
      triggerGeneralSetting,
      generalSettingData,
    } = nextProps;

    if (isSuccessGeneralSetting) {
      if (triggerGeneralSetting === GENERAL_SETTING_STATE.FETCH_ALL_SUCCCESS) {
        generalSettingData.map((data) => {
          if (data.key === 'foss_billing') {
            this.setState({
              fossBillingStatus: data.value === 1 ? true : false,
            });
          }
        });
      }
    }

    if (trigger === STATE.EXPORT_SUCCESS && exportData) {
      toast('success', 'File successfully exported & downloaded');
    }

    if (isError && message) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message)) toast('error', message);
      this.props.getOrganizationType();
    }

    if (isSuccessBank) {
      if (triggerBank === BANK_STATE.GET_SUCCESS) {
        this.setState({
          bankList: bankData,
        });
      }
    }

    if (isSuccessPartner) {
      if (triggerPartner === PARTNER_STATE.FETCH_LIST_SUCCCESS) {
        this.setState({
          partnerList: partnerData,
        });
      }
    }

    if (isSuccess) {
      if (trigger === STATE.CREATE_SUCCESS) {
        this.handleReset();
        this.fetchOrganization();
        this.props.getOrganizationType();
        toast('success', message);
        this.props.clearState();
      }

      if (trigger === STATE.CREATE_CHILD_SUCCESS) {
        this.handleReset();
        this.fetchOrganization();
        toast('success', message);
        this.props.clearState();
      }

      if (trigger === STATE.DROP_SUCCESS) {
        this.fetchOrganization();
        this.props.getOrganizationType();
        toast('success', message);
        this.props.clearState();
      }

      if (trigger === STATE.DROP_CHILD_SUCCESS) {
        this.fetchOrganization();
        this.props.getOrganizationType();
        toast('success', message);
        this.props.clearState();
      }


      if (trigger === STATE.UPDATE_SUCCESS) {
        this.handleReset();
        this.fetchOrganization();
        this.props.getOrganizationType();
        toast('success', message);
        this.props.clearState();
      }

      if (trigger === STATE.INVITATION_SUCCESS) {
        this.handleReset();
        toast('success', 'Your invitation has been sending to their email');
        this.setState({showInvitationModal: false});
        this.props.clearState();
      }

      if (trigger === STATE.INVITATION_FAILED) {
        toast('error', message.message);
      }

      if (trigger === STATE.CHECK_ODOO_SUCCESS) {
        this.setState({
          odooStatus: true,
          odooMessage: message,
        });
      }
    }

    if (isSuccessInvoice) {
      if (triggerInvoice === STATE_INVOICE.GENERATE_SUCCESS) {
        this.handleReset();
        toast('success', messageInvoice);
        this.props.clearStateGenerate();
        this.fetchOrganization();
      }
      if (triggerInvoice === STATE_INVOICE.UPDATE_INVOICE_SUCCESS) {
        this.handleResetModalInvoiceStatus();
        toast('success', messageInvoice);
        this.props.clearStateGenerate();
        this.fetchOrganization();
      }
    }

    if (
      registerTrigger === REGISTER_STATE.APPROVAL_SUCCESS &&
      this.props.registerMessage !== registerMessage
    ) {
      toast('success', registerMessage);
    }

    if (
      registerTrigger === REGISTER_STATE.APPROVAL_FAILED &&
      this.props.registerMessage !== registerMessage
    ) {
      if (!isObject(registerMessage)) toast('error', registerMessage);
    }

    if (isError && message && trigger !== STATE.CHECK_ODOO_FAILED) {
      if (isObject(message)) this.setState({errorMessage: message});
      if (!isObject(message) && message !== 'connection failed') {
        toast('error', message);
      }
      this.fetchOrganization();
      this.props.clearState();
    }

    if (isError && message && trigger === STATE.CHECK_ODOO_FAILED) {
      if (!isObject(message)) {
        this.setState({odooStatus: false, odooMessage: message});
      }
    }

    if (isErrorInvoice && messageInvoice) {
      this.setState({errorMessage: messageInvoice});
      // if(!this.props.messageInvoice) {
      //   if (!isObject(message)) toast('error', messageInvoice);
      // }
    }
  }

  redirectDashboardIfChild = () => {
    const {isSuperAdmin, auth, userFeature} = this.props;

    if (!isSuperAdmin) {
      if (auth.data.organization.is_child) {
        this.props.history.push('/dashboard');
      } else {
        // if(!userFeature.tenant_management){
        //   this.props.history.push('/dashboard');
        // }
      }
    }
  };

  handleResetModalInvoiceStatus = () => {
    this.setState({
      modalChangeInvoiceStatus: false,
      errorMessage: null,
      formInvoice: {
        id: null,
        company_name: null,
        bank_name: null,
        payment_date: null,
        amount: null,
        partner: null,
        plan: null,
        payment_method: null,
        payment_status: null,
        imageURL: null,
        cropped_user_img: null,
      },
    });
  }

  handleInvoiceDate = (params) => {
    const {formInvoice, errorMessage} = this.state;
    const {name, value} = params;
    this.setState({
      formInvoice: {...formInvoice, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleFieldModalStatus = (event) => {
    const {name, value} = event.target;
    const {formInvoice, errorMessage} = this.state;
    this.setState({
      formInvoice: {
        ...formInvoice,
        [name]: value,
      },
      errorMessage: {
        ...errorMessage,
        [name]: null,
      },
    });
  }

  toggleCropperModal = (status) => {
    this.setState({isCropping: status});
  };

  handleCrop = (result) => {
    this.setState({
      formInvoice: {
        ...this.state.formInvoice,
        cropped_user_img: result.blob,
        imageURL: result.imageURL,
      },
    });
  };

  handleSubmitInvoice = (e) => {
    e.preventDefault();
    const {updateInvoiceStatus} = this.props;
    const {formInvoice} = this.state;
    const {
      id,
      company_name,
      payment_date,
      amount,
      partner,
      plan,
      payment_method,
      bank_name,
      payment_status,
      cropped_user_img,
    } = this.state.formInvoice;

    const params = {
      ...(company_name && {company_name}),
      ...(payment_date && {payment_date: convDate(payment_date, 'YYYY-MM-DD')}),
      ...(amount && {amount: amount * 1}),
      ...(partner && {partner}),
      ...(plan && {plan: plan * 1}),
      ...(payment_method && {payment_method: 'Manual Payment'}),
      ...(bank_name && {bank_name}),
      ...(payment_status && {payment_status}),
      status: 'paid',
    };

    const error = [];
    if (!company_name) error.push({company_name: ''});
    if (!payment_date) error.push({payment_date: ''});
    if (!amount) error.push({amount: ''});
    if (!plan) error.push({plan: ''});
    if (!payment_method) error.push({payment_method: ''});
    if (!bank_name) error.push({bank_name: ''});
    if (!payment_status) error.push({payment_status: ''});

    this.setState({errorMessage: {
      ...this.state.errorMessage,
      ...(!company_name && {company_name: 'Company name is required'}),
      ...(!payment_date && {payment_date: 'Payment date is required'}),
      ...(!amount && {amount: 'Amount is required'}),
      // ...(!partner && {partner: 'Partner is required'}),
      ...(!plan && {plan: 'Plan is required'}),
      ...(!payment_method && {payment_method: 'Payment Method is required'}),
      ...(!bank_name && {bank_name: 'Bank Name is required'}),
      ...(!payment_status && {payment_status: 'Payment Status is required'}),
    }});

    if (error.length > 0) {
      return;
    } else {
      const dataForm = new FormData();
      if (company_name) dataForm.append('company_name', company_name);
      if (payment_date) dataForm.append('payment_date', convDate(payment_date, 'YYYY-MM-DD'));
      if (amount) dataForm.append('amount', amount);
      if (partner) dataForm.append('partner', partner);
      if (plan) dataForm.append('plan', plan * 1);
      if (payment_method) dataForm.append('payment_method', 'Manual Payment');
      if (bank_name) dataForm.append('bank_name', bank_name);
      if (payment_status) dataForm.append('payment_status', payment_status);
      if (cropped_user_img) {
        dataForm.append('invoice_img', blobToFile(cropped_user_img));
      }
      dataForm.append('status', 'paid');
      showPopup(
          'confirm',
          `Are you sure you want to send this invoice to ${company_name}?`,
          'Yes',
          () => updateInvoiceStatus(id, dataForm, 'paid'),
      );
    }
  }

  handleSelectSearchInvoice = (event, data, name, type) => {
    const {formInvoice, errorMessage} = this.state;
    this.setState({
      formInvoice: {...formInvoice, [name]: data},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleClickBadge = (e) => {
    this.setState({
      anchorElBadge: e.currentTarget,
    });
  }

  handleCloseBadge = (e) => {
    this.setState({
      anchorElBadge: null,
    });
  }

  fetchStatusUser = () => {
    apiService
        .get('/user/check-status')
        .then((res) => {
          if (!this.props.isSuperAdmin) {
          // if (!res.data.data.tenant_management) {
          //   this.props.history.push("/dashboard");
          // }
          }

          this.setState({userFeature: res.data.data});
        })
        .catch(() => null);
  };
  // componentWillMount() {
  //   const { isSuperAdmin, userFeature } = this.props;

  //   if(isSuperAdmin){
  //   }
  //   else{
  //     if(userFeature.tenant_management){
  //       //return window.location.replace('/company-management');
  //     }
  //     else{
  //       window.location.replace('/dashboard');
  //     }
  //   }
  // }

  handleExport = () => {
    const {
      page,
      limit,
      order,
      searchFilter,
      statusFilter,
      sortBy,
      startDate,
      endDate,
      no_activity,
    } = this.state;
    this.props.exportExcel({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(searchFilter && {query: searchFilter}),
      ...(statusFilter && {status: statusFilter}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(no_activity && {no_activity: no_activity ? true : false}),
    });
  }

  componentDidMount() {
    this.checkApproval();
    // if(userFeature && isSuperAdmin){
    //   this.props.history.push('/company-management');
    // }
    // else{
    //   if(Object.keys(userFeature).length && userFeature.tenant_management){
    //     this.props.history.push('/company-management');
    //   }
    //   else{
    //     this.props.history.push('/dashboard');
    //   }
    // }
  }

  componentDidUpdate(prevState) {
    const {isSuperAdmin, userFeature} = this.props;

    // if(prevProps.userFeature !== this.state.userFeature){
    //   if(!isSuperAdmin){
    //     if(this.state.userFeature && this.state.userFeature.tenant_management){
    //       this.props.history.push('/company-management');
    //     }
    //     else{
    //       this.props.history.push('/dashboard');
    //     }
    //   }
    // }

    // if(!isSuperAdmin){
    //   if(userFeature !== prevProps.userFeature){
    //     if(userFeature.tenant_management){
    //       this.props.history.push('/company-management');
    //     }
    //     else{
    //       this.props.history.push('/dashboard');
    //     }
    //   }
    // }
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  checkApproval = () => {
    const queryParam = this.props.location.search.substring(1); // remove the "?" at the start
    const searchParams = new URLSearchParams(queryParam);
    const id = searchParams.get('id');
    const token = searchParams.get('token');
    let approve = searchParams.get('approve');
    if (approve) {
      if (approve === 'true') approve = true;
      if (approve === 'false') approve = false;
    }

    if (queryParam) {
      this.props.submitApproval({
        ...(id && {id}),
        ...(token && {token}),
        approve,
      });
    }
  };

  fetchOrganization = () => {
    const {isSuperAdmin} = this.props;

    if (isSuperAdmin) {
      const {
        page,
        limit,
        order,
        searchFilter,
        statusFilter,
        sortBy,
        startDate,
        endDate,
        no_activity,
      } = this.state;
      this.props.getAllOrganization({
        page,
        limit,
        order,
        query: searchFilter,
        status: statusFilter,
        sort_by: sortBy,
        start_date: convDate(startDate, 'DD-MM-YYYY'),
        end_date: convDate(endDate, 'DD-MM-YYYY'),
        no_activity,
      });
    } else {
      const {
        page,
        limit,
        order,
        searchFilter,
        sortBy,
        statusFilter,
        startDate,
        endDate,
        no_activity,
      } = this.state;
      this.props.getChildOrganizationForHR({
        page,
        limit,
        order,
        status: statusFilter === 'all' ? '' : statusFilter,
        query: searchFilter,
        sort_by: sortBy,
        start_date: convDate(startDate),
        end_date: convDate(endDate),
        no_activity,
      });
    }
  };

  checkOdoo = () => {
    const {odoo_key, odoo_url} = this.state.formData;
    this.props.checkOdoo({odoo_url, odoo_api_key: odoo_key});
  };

  checkSelectedFeature = () => {
    const {errorMessage} = this.state;
    const {remote, office, business} = this.state.formData.feature;
    const validate = () => {
      if (!remote && !office && !business) return false;
      return true;
    };

    const isValid = validate();
    const message = 'Please select at least 1 (one) required(*) feature';
    if (!isValid) {
      this.setState({errorMessage: {...errorMessage, feature: message}});
    }
    return isValid;
  };

  checkLocation = () => {
    const {errorMessage} = this.state;
    const {latitude, longitude, radius} = this.state.formData;
    const validate = () => {
      if (!latitude && !longitude && !radius) {
        return true;
      } else {
        if (!latitude) return false;
        if (!longitude) return false;
        if (!radius) return false;
        if (radius < 0) return false;
      }
      return true;
    };

    const isValid = validate();
    if (!isValid) {
      this.setState({
        errorMessage: {
          ...errorMessage,
          ...(!latitude && {latitude: 'Latitude is required'}),
          ...(!longitude && {longitude: 'Longitude is required'}),
          ...(!radius && {radius: 'Radius is required'}),
          ...(radius < 0 && {radius: 'Radius must be greather then 0'}),
        },
      });
    }
    return isValid;
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchOrganization());
  };

  handleReset = () => {
    const newLocation = this.state.formData.location;

    while (newLocation.length > 0) {
      newLocation.pop();
    }

    this.setState({
      newLocation,
      formData: this.initialForm,
      modalFormShow: false,
      modalGenerateShow: false,
      errorMessage: null,
      errorImageSize: null,
      showBusinessLimit: false,
      showMultipleHRLimit: false,
      locationsTab: 0,
      featuresTab: 0,
      odooStatus: null,
      odooMessage: null,
    });
  };

  handleInputChange = async (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;
    let regex = value;
    if (name === 'price') {
      regex = value;
    }

    await this.setState({
      formData: {...formData, [name]: regex},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleAttachment = (event) => {
    const {formInvoice, errorMessage} = this.state;
    const {name, value} = event.target;
    if (name === 'user_img') {
      const image = event.target.files[0];
      const imageSizeIsAllowed = checkFileUploadSize(image);
      if (imageSizeIsAllowed) {
        this.setState({
          isCropping: true,
          errorImageSize: null,
          errorMessage: {...errorMessage, user_img: null},
          formInvoice: {
            ...formInvoice,
            imageURL: URL.createObjectURL(image),
            user_img: image,
          },
        });
      } else {
        let message;
        if (imageSizeIsAllowed === false) {
          message =
            'Maximum file size is ' +
            process.env.REACT_APP_MAX_UPLOAD_SIZE_MB +
            ' MB';
        }
        if (imageSizeIsAllowed === null) message = 'Please select image';
        this.setState({
          errorImageSize: message,
          imageURL: null,
          user_img: null,
        });
      }
    } else {
      this.setState({
        formInvoice: {...formInvoice, [name]: value},
        errorMessage: {...errorMessage, [name]: null},
        errorMessageRemote: null,
        errorMessageAdditionalRemote: null,
      });
    }
  };

  handleInputChangeAdvantech = async (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;

    await this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleInputChangeCurrency = (event) => {
    // const {formData, errorMessage} = this.state;
    const {value} = event.target;
    const regex = value
        .split('.')
        .join('')
        .replace(/[a-zA-Z!\s@#\$%\^\&*\)\(+=._-`~{}|:;<>?/]+/g, '');

    this.setState({
      formData: {
        ...this.state.formData,
        price: regex ? formatCurrency(regex) : regex,
      },
      fieldState: false,
    });
  };

  handleMultipleChange = (name, value) => {
    const {formData, errorMessage} = this.state;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleImageChange = (event) => {
    const {formData, errorMessage} = this.state;
    const logo = event.target.files[0];
    const imageSizeIsAllowed = checkFileUploadSize(logo);
    if (imageSizeIsAllowed) {
      this.setState({
        errorImageSize: null,
        errorMessage: {...errorMessage, logo: null},
        formData: {
          ...formData,
          logo: logo,
          logoURL: URL.createObjectURL(logo),
        },
      });
    } else {
      let message;
      if (imageSizeIsAllowed === false) {
        message =
          'Maximum file size is ' +
          process.env.REACT_APP_MAX_UPLOAD_SIZE_MB +
          ' MB';
      }
      if (imageSizeIsAllowed === null) message = 'Please select image';
      this.setState({
        errorImageSize: message,
        logo: null,
        logoURL: null,
      });
    }
  };

  handleCheckboxChange = (event, params) => {
    const {formData, errorMessage} = this.state;
    const {name, checked} = event.target;
    this.setState(
        {
          errorMessage: {...errorMessage, feature: null},
          formData: {
            ...formData,
            feature: {...formData.feature, [name]: checked},
          },
          ...(name === 'business' && {showBusinessLimit: checked}), // change showBusinessLimit state when business checkbox changed
          ...(name === 'multiple_hr' && {showMultipleHRLimit: checked}), // change showMultipleHRLimit state when business checkbox changed
        },
        () => {
          if (name === 'business' || name === 'payslip' || name === 'advantech' || name === 'multiple_hr') {
            this.checkSelectedFeature();
            if (checked) this.handleCheckTab(params, name);
            if (checked === false) this.setState({featuresTab: 0});
          }
        },
    );
  };

  handleCheckboxFilter = (event, params) => {
    // const {formData, errorMessage} = this.state;
    const {name, checked} = event.target;
    this.setState(
        {
          [name]: checked,
          page: 1,
          startDate: name === 'no_activity' && null,
          endDate: name === 'no_activity' && null,
        },
        () => {
          this.toggleResetPage();
          this.fetchOrganization();
        },
    );
  };

  handleGetOrganizationType = (orgType) => {
    return this.props.orgTypeData.filter((item) => item.name === orgType);
  };

  handleMappingArray = (array) => {
    return array.map((value) => value.id);
  };

  handleFormToggle = (reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    const {modalFormShow} = this.state;
    this.handleReset();
    this.setState({modalFormShow: !modalFormShow, isEdit: false});
  };

  handleInputNPWP = (event) => {
    const {name, value} = event.target;
    const {formData, errorMessage} = this.state;

    if(name === 'npwp_holder_name') {
      if(value.length < 4) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            ...{npwp_holder_name: 'NPWP holder must not be less than 4 character'},
          },
          formData: {
            ...formData,
            [name] : value
          }
        })
      } else {
        this.setState({
          errorMessage: {
            ...errorMessage,
            ...{npwp_holder_name: null},
          },
          formData: {
            ...formData,
            [name]: value,
          }
        })
      }
    } else {
      let validator = /[a-zA-Z]/g.test(value);
      
      if(validator) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            ...{npwp_id: 'NPWP ID cannot input letter character'},
          },
          formData: {
            ...formData,
            [name]: value,
          },
        });
      } else {
        this.setState({
          errorMessage: {
            ...errorMessage,
            ...{npwp_id: null},
          },
          formData: {
            ...formData,
            [name]: value,
          }
        });
      }
    }
  }

  // ✨ invitation organization for superadmin HR role
  handleSubmitInvitationOrg = (event) => {
    event.preventDefault();
    const {pic_email} = this.state.formData;
    const {organizationHrInvitation} = this.props;

    const params = {
      email: pic_email,
    };

    organizationHrInvitation(params);
  };

  handleInvitationSubmit = (event) => {
    event.preventDefault();

    const {pic_email} = this.state.formData;
    const {invitationOrganization} = this.props;

    const payload = {
      pic_email: pic_email,
    };

    return invitationOrganization(payload);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      createOrganization,
      createChildOrganization,
      updateOrganization,
      isSuperAdmin,
    } = this.props;
    const {isEdit} = this.state;
    const {
      id,
      name,
      organization_type_id,
      logo,
      feature,
      business_limit,
      hr_limit,
      user_limit,
      pic_name,
      pic_number,
      expired_date,
      pic_email,
      price,
      adv_key,
      adv_value,
      adv_url,
      location,
      adv_url_local,
      odoo_key,
      odoo_url,
      payslip_limit,
      parent_name,
      status,
      partner_id,
      npwp_id,
      npwp_holder_name,
    } = this.state.formData;
    const newLocation = location.filter((value) => {
      return value !==null;
    });

    // return console.log(newLocation);

    const payload = {
      organization_type_id: organization_type_id,
      name: name,
      pic_name: pic_name,
      pic_number: pic_number,
      pic_email: pic_email,
    };

    const {
      auth: {
        data: {organization},
      },
    } = this.props;
    const errors = {};

    if (isSuperAdmin && !user_limit && !parent_name) {
      if (!user_limit) errors.user_limit = 'User limit is required';
      this.setState({errorMessage: errors});
    } else {
      if (isSuperAdmin && !this.checkSelectedFeature()) return;

      const dataForm = new FormData();

      if(npwp_id) dataForm.append('npwp_id', npwp_id);
      if(npwp_holder_name) dataForm.append('npwp_holder_name', npwp_holder_name);
      
      if (name || name === '') dataForm.append('name', name);
      if (organization_type_id) {
        dataForm.append('organization_type_id', organization_type_id);
      }
      if (
        isSuperAdmin &&
        Array.isArray(newLocation) &&
        newLocation[0] &&
        newLocation[0]['address']
      ) {
        dataForm.append('address', newLocation[0]['address']);
      }
      if (isSuperAdmin && logo) dataForm.append('logo', logo);
      if (pic_name) dataForm.append('pic_name', pic_name);
      if (pic_number) dataForm.append('pic_number', pic_number);
      if (partner_id) dataForm.append('partner_id', partner_id * 1);
      if (pic_email) dataForm.append('pic_email', pic_email);
      if (isSuperAdmin && price) {
        dataForm.append('price', price.split('.').join(''));
      }
      if (isSuperAdmin && feature) {
        dataForm.append('feature', JSON.stringify(feature));
      }
      if (isSuperAdmin && expired_date) {
        dataForm.append('expired_date', expired_date);
      }
      if (business_limit || business_limit === 0) {
        dataForm.append('business_limit', business_limit);
      }
      if (hr_limit || hr_limit === 0) {
        dataForm.append('hr_limit', hr_limit);
      }
      // if (isSuperAdmin && newLocation[0]['lat']) {
      //   dataForm.append('latitude', newLocation[0]['lat']);
      // }
      // if (isSuperAdmin && newLocation[0]['long']) {
      //   dataForm.append('longitude', newLocation[0]['long']);
      // }
      // if (isSuperAdmin && newLocation[0]['radius']) {
      //   dataForm.append('radius', newLocation[0]['radius']);
      // }
      if (newLocation.length > 0) {
        if (isSuperAdmin && newLocation[0]['lat'] === null) {
          dataForm.append('latitude', '0');
        }
        if (isSuperAdmin && newLocation[0]['long'] === null) {
          dataForm.append('longitude', '0');
        }
        if (isSuperAdmin && newLocation[0]['radius'] === null) {
          dataForm.append('radius', 0);
        }
      } else {
          dataForm.append('latitude', '0');
          dataForm.append('longitude', '0');
          dataForm.append('radius', 0);
      }
      if (user_limit) dataForm.append('user_limit', user_limit);
      if (isEdit && status) dataForm.append('status', status);
      if (adv_key) dataForm.append('adv_key', adv_key);
      if (adv_value) dataForm.append('adv_value', adv_value);
      if (adv_url) dataForm.append('adv_url', adv_url);
      if (isSuperAdmin && newLocation.length > 1) {
        dataForm.append('location', JSON.stringify(newLocation));
      }
      if (isSuperAdmin && newLocation.length === 1) {
        dataForm.append('location', '[]');
      }
      if (adv_url_local) dataForm.append('adv_url_local', adv_url_local);
      if (odoo_key) dataForm.append('odoo_api_key', odoo_key);
      if (odoo_url) dataForm.append('odoo_url', odoo_url);
      if (payslip_limit) dataForm.append('payslip_limit', payslip_limit);

      // const payload = new FormData();
      // payload.append('organization_type_id', organization_type_id);
      // payload.append('name', name);
      // payload.append('pic_name', pic_name);
      // payload.append('pic_number', pic_number);
      // payload.append('pic_email', pic_email);

      return !isEdit ?
        isSuperAdmin ?
          createOrganization(dataForm) :
          createChildOrganization(organization.id, payload) :
        updateOrganization(id, dataForm);
    }
  };

  handleChangeAddress = (event, id) => {
    const {formData} = this.state;
    const {location} = this.state.formData;
    const newLocation = location;

    if (event.target.name === 'address') {
      newLocation[id][event.target.name] = event.target.value;
    } else if (event.target.name === 'name') {
      const {name} = event.target;
      newLocation.push({id: id, name: event.target.value});
      newLocation[id][event.target.name] = event.target.value;
    } else {
      newLocation[id][event.target.name] = parseFloat(event.target.value);
    }

    this.setState({
      formData: {
        ...formData,
        ...location,
        location: newLocation,
      },
    });
  };

  handleConfirmDelete = (data) => {
    const {isSuperAdmin, deleteOrganization, deleteChildOrganization} =
      this.props;

    const payload = {
      pic_email: isSuperAdmin ? data[31] : data[30],
    };

    const formData = new FormData();
    formData.append('pic_email', data[31]);

    showPopup(
        'confirm',
      isSuperAdmin ?
        'Are you sure you want to delete this data ?' :
        'Are you sure want to remove this company ?',
      'Yes',
      () =>
        isSuperAdmin ?
          deleteOrganization(data[1]) :
          deleteChildOrganization(payload),
    );
  };

  handleEditDialogToggle = async (data) => {
    console.log(data);
    const {modalFormShow} = this.state;
    const {organizationData} = this.props;

    // console.log(data);

    const dataOrg = organizationData.filter((val) => {
      return val.id === data[1];
    });

    const orgType = data[13] ? this.handleGetOrganizationType(data[13]) : null;


    this.props.getAdmins();
    this.setState({
      isEdit: true,
      modalFormShow: !modalFormShow,
      errorMessage: null,
      showBusinessLimit: data[18] && data[18].business ? true : false, // show business limit input dynamicall
      showMultipleHRLimit: data[18] && data[18].multiple_hr ? true : false, // show HR limit input dynamicall
      formData: {
        id: data[1],
        name: data[2],
        partner_name: data[3],
        partner_id: data[36],
        country_code: data[12],
        organization_type_id: orgType && orgType[0] ? orgType[0].id : '',
        status: data[9],
        address: data[14],
        description: data[15],
        logoURL: data[16],
        logo: null, // default when edited is null, changed when image is selected
        // user_ids: data[7] ? this.handleMappingArray(data[]) : [], // only array of selected ids, for multiple select
        // users: data[8], // arrayObject of users
        feature: data[18] !== undefined ? data[18] : [],
        business_limit: data[19],
        hr_limit: data[37] ? data[37] : 1,
        latitude: data[20],
        longitude: data[21],
        radius: data[22],
        user_limit: data[4],
        pic_name: dataOrg[0].pic_name,
        pic_number: dataOrg[0].pic_number,
        pic_email: dataOrg[0].hr_admin,
        price:
          dataOrg[0] && dataOrg[0]?.price ?
            formatCurrency(dataOrg[0].price) :
            null,
        expired_date: data[8],
        adv_key: data[25],
        adv_value: data[26],
        adv_url: data[27],
        location:
          data[28] && data[28].length !== 0 ?
            data[28] :
            [
              {
                address: data[14],
                lat: data[20],
                long: data[21],
                radius: data[22],
                name: data[2],
              },
            ],
        adv_url_local: data[29],
        odoo_key: null,
        odoo_url: null,
        payslip_limit: null,
        prevOdooUrl: data[31],
        prevOdooKey: data[29],
        npwp_id: data[39],
        npwp_holder_name: data[40],
      },
    });
  };

  handleLoginToChild = () => {
    //
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({searchFilter: value});
    this.handleDebounceSearch(value);
  };

  changeField = () => {
    this.setState({
      fieldState: true,
    });
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: 1}, () => this.fetchOrganization());
  }, 1000);

  handleResetFilter = () => {
    const state = {
      page: 1,
      limit: 10,
      searchFilter: '',
      order: 'desc',
      statusFilter: 'all',
      startDate: null,
      endDate: null,
      sortBy: null,
    };
    this.setState(state, () => this.fetchOrganization());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null}, () =>
      this.fetchOrganization(),
    );
    this.toggleResetPage();
  };

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState({[name]: value, page: null}, () =>
      this.fetchOrganization(),
    );
    this.toggleResetPage();
    this.toggleResetDatatable(true);
  };

  renderModalChangeInvoiceStatus = () => {
    const {
      company_name,
      payment_date,
      amount,
      partner,
      plan,
      payment_method,
      bank_name,
      payment_status,
      user_img,
      imageURL,
    } = this.state.formInvoice;

    const {errorImageSize, showCropResult, isCropping} = this.state;


    const {bankList, partnerList} = this.state;
    const {partnerData} = this.props;

    const PlanOption = [
      {id: 1, name: '1 Month'},
      {id: 3, name: '3 Month'},
      {id: 6, name: '6 Month'},
      {id: 12, name: '12 Month'},
    ];

    const PaymentOption = [
      {id: 'manual_transfer', name: 'Manual Transfer'},
    ];

    const StatusOption = [
      {id: 'Approved', name: 'Approved'},
    ];

    const bankOption = () => {
      const option = [];
      bankList.map((item) => {
        const isDuplicate = option.find((obj) => obj.name === item.name);
        if (!isDuplicate) {
          option.push(item.name);
        }
      });
      return option;
    };

    const partnerOption = () => {
      const option = [];
      partnerData.map((item) => {
        const isDuplicate = option.find((obj) => obj.partner_name === item.partner_name);
        if (!isDuplicate) {
          option.push(item.partner_name);
        }
      });
      return option;
    };

    const {errorMessage, modalChangeInvoiceStatus} = this.state;
    const {classes, isLoading, bankData} = this.props;
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <Dialog
          open={modalChangeInvoiceStatus}
          onClose={() => this.handleResetModalInvoiceStatus()}
          maxWidth='md'
          fullWidth={true}
          scroll='paper'
          titlestyle={{textalign: 'center'}}
        >
          <ModalTitle
            className={classes.modalTitle}
            title={'Create Invoice & Payment Confirmation'}
            // title={"Edit Invoice"}
            onClose={() => this.handleResetModalInvoiceStatus()}
          />
          <form onSubmit={this.handleSubmitInvoice}>
            <DialogContent className={classes.dialogContentEdit}>
              <Grid container spacing={2} >
                <Grid item xs={12}>
                  <MInput
                    classNameFC={classes.formControl}
                    autoFocus
                    fullWidth={true}
                    variant="outlined"
                    name="company_name"
                    label="Company Name"
                    placeholder="Enter company name"
                    defaultValue={company_name}
                    error={errorMessage && errorMessage.company_name ? errorMessage.company_name : undefined}
                    onBlur={this.handleFieldModalStatus}
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} >
                <Grid item xs={6}>
                  <MDatepicker
                    className={classes.formControl}
                    name="payment_date"
                    label="Payment Date *"
                    value={payment_date}
                    onDateChange={this.handleInvoiceDate}
                    error={
                      errorMessage && errorMessage.payment_date ? errorMessage.payment_date : undefined
                    }
                    placeholder="dd-mm-yyyy"
                    inputVariant="outlined"
                    // maxDate={new Date()}
                    // disableFuture
                    openTo="year"
                    views={['year', 'month', 'date']}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <MInput
                    classNameFC={classes.formControl}
                    autoFocus
                    fullWidth={true}
                    variant="outlined"
                    name="amount"
                    label="Amount *"
                    placeholder="Enter amount"
                    defaultValue={amount}
                    error={errorMessage && errorMessage.amount ? errorMessage.amount : undefined}
                    onBlur={this.handleFieldModalStatus}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} >
                {/* <Grid item xs="12">
                  <MSelectSearch
                    disableClearable
                    name="partner"
                    options={partnerOption()}
                    classNameFC={classes.formControl}
                    label="Partner"
                    value={partner}
                    onChange={(e, value) => {
                      this.handleSelectSearchInvoice(e, value, 'partner', 'form');
                    }}
                    variant={'outlined'}
                    error={
                      errorMessage && errorMessage.partner ?
                        errorMessage.partner :
                        undefined
                    }
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <MSelect
                    classNameFC={classes.formControl}
                    fullWidth={true}
                    name="plan"
                    label="Plan *"
                    value={plan}
                    onChange={this.handleFieldModalStatus}
                    error={
                      errorMessage && errorMessage.plan ?
                        errorMessage.plan :
                        undefined
                    }
                    keyPair={['id', 'name']}
                    options={PlanOption ? PlanOption : []}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MSelectSearch
                    disableClearable
                    name="bank_name"
                    options={bankOption()}
                    classNameFC={classes.formControl}
                    label="Bank Name *"
                    value={bank_name}
                    onChange={(e, value) => {
                      this.handleSelectSearchInvoice(e, value, 'bank_name', 'form');
                    }}
                    variant={'outlined'}
                    error={
                      errorMessage && errorMessage.bank_name ?
                        errorMessage.bank_name :
                        undefined
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} style={{marginTop: '10px'}}>
                <>
                  <MInput
                    classNameFC={classes.formControl}
                    name="user_img"
                    label="Attachment"
                    placeholder="Photo"
                    type="file"
                    onChange={this.handleAttachment}
                    inputProps={{accept: 'image/*'}}
                    error={
                    errorImageSize ?
                      errorImageSize :
                      errorMessage && errorMessage.user_img ?
                      errorMessage.user_img :
                      undefined
                    }
                  />
                  <span><i>Must be .jpg/.jpeg with recommended resolution 200 x 200 px</i></span>
                  {!isCropping && imageURL && (
                    <div>
                      <CardMedia
                        className={classes.photo}
                        title="Photo"
                        image={imageURL}
                      />
                      {user_img && (
                        <MButton
                          className={`${classes.defaultBtn} ${classes.btnCrop}`}
                          label="Retry"
                          icon={<Crop />}
                          onClick={() => this.toggleCropperModal(true)}
                        />
                      )}
                    </div>
                  )}
                  <ImageCropper
                    showModal={isCropping}
                    src={user_img}
                    showResult={showCropResult}
                    onClose={() => this.toggleCropperModal(false)}
                    onComplete={this.handleCrop}
                  />
                </>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogFooter}>
              <MButton
                className={classes.cancelBtn}
                label="Cancel"
                onClick={() => this.handleResetModalInvoiceStatus()}
              />
              <MButton
                className={classes.defaultBtn}
                type="submit"
                label={'Update'}
                icon={<Send/>}
                loading={isLoading ? true : false}
              />
            </DialogActions>
          </form>
        </Dialog>
      </MuiThemeProvider>
    );
  }


  renderFilter = () => {
    const {classes, message, isSuperAdmin, exportLoading, exportData} = this.props;
    const {searchFilter, startDate, endDate, statusFilter, no_activity} =
      this.state;
    const renderMethodAdminOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: 'paid', name: 'Paid'},
        {id: 'unpaid', name: 'Unpaid'},
      ];
      return options;
    };
    const renderMethodHROptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        {id: '0', name: 'Invited'},
        {id: '1', name: 'Approved'},
      ];
      return options;
    };

    return (
      <Paper variant='outlined' className={classes.paper}>
        <Grid container alignItems="center">
          <MInput
            autoFocus
            className={`${classes.filter} ${classes.searchInput}`}
            fullWidth={false}
            variant="outlined"
            name="query"
            label="Search"
            placeholder="Enter Search Keyword"
            value={searchFilter}
            onChange={this.handleSearch}
            InputProps={{
              endAdornment: (
                <IconButton className={classes.searchIcon} position="end">
                  {' '}
                  <Search />
                </IconButton>
              ),
            }}
          />
          <MSelect
            shrink={true}
            fullWidth={false}
            classNameFC={`${classes.formControl} ${classes.filter} ${classes.searchSelect}`}
            variant="outlined"
            name="statusFilter"
            label="Status"
            keyPair={['id', 'name']}
            options={
              isSuperAdmin ?
                renderMethodAdminOptions() :
                renderMethodHROptions()
            }
            value={statusFilter}
            onChange={this.handleFilterChange}
          />
          <MDatepicker
            className={`${classes.filter} ${classes.formControl} ${classes.searchDate}`}
            inputVariant="outlined"
            name="startDate"
            label="Last Activity Start Date"
            value={startDate}
            onDateChange={this.handleFilterDateChange}
            placeholder="dd-mm-yyyy"
            maxDate={endDate ? new Date(endDate) : undefined}
            error={
              message && message.start_date ? message.start_date : undefined
            }
            disabled={no_activity}
          />
          <MDatepicker
            className={`${classes.filter} ${classes.formControl} ${classes.searchDate}`}
            inputVariant="outlined"
            name="endDate"
            label="Last Activity End Date"
            value={endDate}
            onDateChange={this.handleFilterDateChange}
            placeholder="dd-mm-yyyy"
            minDate={new Date(startDate)}
            error={message && message.end_date ? message.end_date : undefined}
            disabled={no_activity}
          />
          <MCheckboxItem
            classNameFC={classes.checkBoxFilter}
            name="no_activity"
            label="No Activity"
            onChange={this.handleCheckboxFilter}
            checked={no_activity}
          />
          <div className={classes.btnFilterContainer}>
            <MButton
              className={classes.defaultBtn}
              label="Reset"
              icon={<Refresh />}
              onClick={this.handleResetFilter}
            />
          </div>
          <div className={classes.btnFilterContainer}>
            <MButton
              className={classes.defaultBtn}
              label="Export"
              icon={<SaveAlt />}
              loading={exportLoading}
              onClick={this.handleExport}
              style={{marginLeft: 18}}
              // disabled={exportData?.length < 1 ? true : false}
            />
          </div>
        </Grid>
      </Paper>
    );
  };

  handleAnalytics = (params) => {
    const baseURL = window.location.origin;
    const id = params[1];
    const date = params[25];
    window.location.href =
      baseURL +
      '/organization-analytics?id=' +
      id +
      '&startDate=' +
      date +
      '&endDate=' +
      date;
  };

  handleUserList = (params) => {
    const baseURL = window.location.origin;
    const id = params[1];
    const date = params[26];
    const status = params[6];
    const company = params[2];
    window.location.href =
      baseURL +
      '/employees-list?id=' +
      id +
      '&date=' +
      date +
      '&status=' +
      status +
      '&company=' +
      company;
  };

  handleChangeTab = (newValue, name) => {
    this.setState({
      [name]: newValue,
    });
  };

  handleCheckTab = (params, name) => {
    const check = params.some((i) => i.name.includes(name));
    if (name === 'business') {
      this.setState({
        featuresTab: 0,
      });
    } else if (name === 'advantech') {
      if (params.some((i) => i.name.includes('business'))) {
        this.setState({
          featuresTab: 1,
        });
      } else {
        this.setState({
          featuresTab: 0,
        });
      }
    } else if (name === 'payslip') {
      if (params.length === 2) {
        this.setState({
          featuresTab: 2,
        });
      } else if (
        params.some((i) => i.name.includes('business')) ||
        params.some((i) => i.name.includes('advantech'))
      ) {
        this.setState({
          featuresTab: 1,
        });
      } else {
        this.setState({
          featuresTab: 0,
        });
      }
    }
  };

  // renderActions = (params) => {
  //   console.log(params);
  //   const {classes, isSuperAdmin} = this.props;

  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //       }}
  //     >
  //       {isSuperAdmin ? (
  //         <Tooltip title="Employees" className={classes.childCustomActions}>
  //           <Button
  //             variant="contained"
  //             // color="primary"
  //             size="small"
  //             style={{
  //               'backgroundColor': '#ef9c02',
  //               'color': '#fff',
  //               '&:hover': {
  //                 backgroundColor: '#c98200',
  //               },
  //               'minWidth': '24px',
  //               'paddingRight': '0',
  //               'marginRight': 5,
  //             }}
  //             onClick={() => this.handleUserList(params)}
  //             startIcon={<PeopleAltOutlined />}
  //           ></Button>
  //         </Tooltip>
  //       ) : null}
  //       {isSuperAdmin ? (
  //         !params[3] ? (
  //           params[11]?.tenant_management ? (
  //             null
  //             // <Tooltip
  //             //   title="List Child Company"
  //             //   className={classes.childCustomActions}
  //             // >
  //             //   <Button
  //             //     variant="contained"
  //             //     // color="primary"
  //             //     size="small"
  //             //     style={{
  //             //       backgroundColor: "#12e619",
  //             //       color: "#fff",
  //             //       "&:hover": {
  //             //         backgroundColor: "#c98200",
  //             //       },
  //             //       minWidth: "24px",
  //             //       paddingRight: "0",
  //             //       marginRight: 5,
  //             //     }}
  //             //     onClick={() => {
  //             //       window.open(
  //             //         `/company-management/${params[1]}`,
  //             //         "_incognito"
  //             //       );
  //             //       //window.create({ "url": `/company-management/${params[1]}`, "incognito": true })
  //             //     }}
  //             //     startIcon={<ApartmentOutlinedIcon />}
  //             //   ></Button>
  //             // </Tooltip>
  //           ) : null
  //         ) : null
  //       ) : null}
  //       {/* {isSuperAdmin ? (
  //         // <Tooltip
  //         //   title="Login to HR Company"
  //         //   className={classes.childCustomActions}
  //         // >
  //         //   <Button
  //         //     variant="contained"
  //         //     // color="primary"
  //         //     size="small"
  //         //     style={{
  //         //       backgroundColor: "#782fa8",
  //         //       color: "#fff",
  //         //       "&:hover": {
  //         //         backgroundColor: "#c98200",
  //         //       },
  //         //       minWidth: "24px",
  //         //       paddingRight: "0",
  //         //       marginRight: 5,
  //         //     }}
  //         //     startIcon={<Input />}
  //         //     onClick={this.handleLoginToChild()}
  //         //   ></Button>
  //         // </Tooltip>
  //       ) : null} */}
  //     </div>
  //   );
  // };

  renderActions = (params) => {
    const {anchorEl, menuParams} = this.state;

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      this.setState({
        anchorEl: event.currentTarget,
        menuParams: params,
      });
    };

    const handleClose = () => {
      this.setState({
        anchorEl: null,
      });
    };

    const ITEM_HEIGHT = 48;

    return (
      <Grid container wrap='nowrap' direction='row'>
        <Grid item>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem onClick={() => this.handleEditDialogToggle(menuParams)}>Update</MenuItem>
            <MenuItem onClick={() => this.handleUserList(menuParams)}>List Employee</MenuItem>
            <MenuItem onClick={() => this.handleLoginHR(menuParams)}>Login As HR</MenuItem>
            <MenuItem onClick={() => this.handleConfirmDelete(menuParams)}>Delete</MenuItem>
          </Menu>
        </Grid>
      </Grid>
    );
  };

  handleLoginHR = (params) => {
    this.props.assignOrganizationHR({
      organization_id: params[1],
    });
  }

  handleInvoicePrice = (event) => {
    const {formData} = this.state;
    const regex = event.target.value;
    this.setState({
      formData: {
        ...formData,
        amount: regex,
      },
      errorMessage: {
        ...this.state.errorMessage,
        price: null,
      },
    });
  };

  handleInvoiceDateChange = (event) => {
    const {formData} = this.state;
    this.setState({
      formData: {
        ...formData,
        [event.name]: event.value ? convDate(event.value) : null,
      },
      errorMessage: {
        ...this.state.errorMessage,
        [event.name]: null,
      },
    });
  };

  handleInputChangeInvoice = (event) => {
    const {formData} = this.state;
    this.setState({
      formData: {
        ...formData,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleTogglePassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  };

  handleSubmitGenerate = (event) => {
    event.preventDefault();
    const {generateInvoice} = this.props;
    const {
      organization_id,
      start_period,
      end_period,
      due_date,
      amount,
      remark,
      pic_name_invoice,
      pic_number_invoice,
    } = this.state.formData;
    const params = {
      organization_id,
      ...(start_period && {start_period}),
      ...(end_period && {end_period}),
      ...(due_date && {due_date}),
      ...(amount && {price: amount}),
      ...(pic_name_invoice && {pic_name: pic_name_invoice}),
      ...(pic_number_invoice && {pic_number: pic_number_invoice}),
      ...(remark && {remarks: remark}),
    };
    return generateInvoice(params);
  };

  renderGenerateInvoice = () => {
    const {classes, isLoadingInvoice} = this.props;
    const {modalGenerateShow, errorMessage} = this.state;
    const {
      start_period,
      end_period,
      remark,
      amount,
      due_date,
      organization_name,
      organization_address,
    } = this.state.formData;
    this.setState();
    return (
      <Dialog
        open={modalGenerateShow}
        onClose={() => this.handleModalGenerate('close')}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
        titlestyle={{textalign: 'center'}}
      >
        <ModalTitle
          title={'Generate Invoice'}
          onClose={() => this.handleModalGenerate('close')}
        />
        <form onSubmit={this.handleSubmitGenerate}>
          <DialogContent className={classes.dialogContent}>
            <div>
              <table>
                <tr>
                  <td>
                    <b>Company</b>:{' '}
                  </td>
                  <td>{organization_name}</td>
                </tr>
                <tr>
                  <td>
                    <b>Address </b>:{' '}
                  </td>
                  <td style={{wordBreak: 'break-word'}}>
                    {organization_address}
                  </td>
                </tr>
              </table>
            </div>
            <Grid container wrap="nowrap" spacing={6}>
              <Grid item xs="6">
                <MInput
                  className={`${classes.formControl}`}
                  fullWidth={true}
                  variant="outlined"
                  name="pic_name_invoice"
                  label="PIC Name"
                  placeholder="Enter Pic Name..."
                  defaultValue={''}
                  error={
                    errorMessage && errorMessage.pic_name ?
                      errorMessage.pic_name :
                      undefined
                  }
                  onBlur={this.handleInputChangeInvoice}
                />
              </Grid>
              <Grid item xs="6">
                <MInput
                  className={`${classes.formControl}`}
                  fullWidth={true}
                  variant="outlined"
                  name="pic_number_invoice"
                  label="PIC Number"
                  placeholder="Enter Pic Number..."
                  defaultValue={''}
                  error={
                    errorMessage && errorMessage.pic_number ?
                      errorMessage.pic_number :
                      undefined
                  }
                  onBlur={this.handleInputChangeInvoice}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={6}>
              <Grid item xs="6">
                <MDatepicker
                  className={classes.formControl}
                  name="invoice_date"
                  label="Invoice Date"
                  value={convDate(new Date())}
                  disabled
                  onDateChange={(params) =>
                    this.handleInvoiceDateChange(params)
                  }
                  // error={errorMessage && errorMessage.start_date ? errorMessage.start_date : undefined}
                  placeholder="dd-mm-yyyy"
                  inputVariant="outlined"
                  views={['year', 'month', 'date']}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs="6">
                <MDatepicker
                  className={classes.formControl}
                  name="due_date"
                  label="Due Date"
                  value={due_date}
                  onDateChange={(params) =>
                    this.handleInvoiceDateChange(params)
                  }
                  error={
                    errorMessage && errorMessage.due_date ?
                      errorMessage.due_date :
                      undefined
                  }
                  placeholder="dd-mm-yyyy"
                  inputVariant="outlined"
                  views={['year', 'month', 'date']}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={6}>
              <Grid item xs="6">
                <MDatepicker
                  className={classes.formControl}
                  name="start_period"
                  label="Start Periode"
                  value={start_period}
                  onDateChange={(params) =>
                    this.handleInvoiceDateChange(params)
                  }
                  error={
                    errorMessage && errorMessage.start_period ?
                      errorMessage.start_period :
                      undefined
                  }
                  placeholder="dd-mm-yyyy"
                  maxDate={end_period ? new Date(end_period) : undefined}
                  inputVariant="outlined"
                  views={['year', 'month', 'date']}
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs="6">
                <MDatepicker
                  className={classes.formControl}
                  name="end_period"
                  label="End Periode"
                  value={end_period}
                  onDateChange={(params) =>
                    this.handleInvoiceDateChange(params)
                  }
                  error={
                    errorMessage && errorMessage.end_period ?
                      errorMessage.end_period :
                      undefined
                  }
                  placeholder="dd-mm-yyyy"
                  minDate={start_period ? new Date(start_period) : undefined}
                  inputVariant="outlined"
                  views={['year', 'month', 'date']}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={6}>
              <Grid item xs="4">
                <MInput
                  name="currency"
                  label="Currency"
                  placeholder="Currency"
                  autoComplete="off"
                  disabled
                  value={'IDR'}
                />
              </Grid>
              <Grid item xs="8">
                <MInput
                  name="amount"
                  type="text"
                  label="Amount test"
                  placeholder="Enter Amount"
                  autoComplete="off"
                  defaultValue={amount}
                  onBlur={this.handleInvoicePrice}
                  error={
                    errorMessage && errorMessage.price ?
                      errorMessage.price :
                      undefined
                  }
                />
              </Grid>
            </Grid>
            <MInput
              name="remark"
              type="text"
              label="Remark"
              placeholder="Enter Remarks"
              autoComplete="off"
              defaultValue={remark}
              onBlur={this.handleInputChangeInvoice}
              error={
                errorMessage && errorMessage.price ?
                  errorMessage.price :
                  undefined
              }
              multiline
            />
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              label="Cancel"
              onClick={() => this.handleModalGenerate('close')}
            />
            <MButton
              className={classes.defaultBtn}
              label={'Create'}
              icon={<Send />}
              type="submit"
              loading={isLoadingInvoice ? true : false}
              onClick={this.handleSubmitGenerate}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  renderForm = () => {
    const {classes, isSuperAdmin, isLoading, orgTypeData, modalLoading, partnerList} =
      this.props;
    const {
      isEdit,
      errorMessage,
      errorImageSize,
      showBusinessLimit,
      showMultipleHRLimit,
      locationsTab,
      featuresTab,
      odooStatus,
      odooMessage,
    } = this.state;
    const {
      name,
      parent_name,
      organization_type_id,
      logoURL,
      feature,
      business_limit,
      user_limit,
      hr_limit,
      pic_name,
      pic_number,
      expired_date,
      pic_email,
      price,
      adv_key,
      adv_value,
      adv_url,
      location,
      adv_url_local,
      odoo_key,
      odoo_url,
      payslip_limit,
      prevOdooKey,
      prevOdooUrl,
      status,
      partner_name,
      npwp_id,
      npwp_holder_name,
    } = this.state.formData;

    const titleDialog = isEdit ? 'Update Company' : 'Create Company';
    // const merged = [...orgAdminData, ...users];

    const featureArray = Object.keys(feature).map((key) => {
      return {name: key, value: feature[key]};
    });
    const optionData = [];
    if (partnerList) {
      partnerList.map((item) => {
        optionData.push({id: item.id, label: item.partner_name});
      });
    }

    const top100Films = [
      {id: 230, label: 'Partner Testing'},
      {id: 232, label: 'partner Sahira'},
      {id: 246, label: 'partner Nabila'},
      {id: 255, label: 'Test create cms'},
      {id: 258, label: 'partner1!'},
      {id: 259, label: 'partner1!'},
    ];


    const advanceFeat =
      featureArray &&
      featureArray.filter((value) => {
        return (
          (value.name === 'business' ||
            value.name === 'advantech' ||
            value.name === 'payslip' ||
            value.name === 'multiple_hr'
          ) && value.value === true
        );
      });

    const disabled =
      (!odoo_key ||
        !odoo_url ||
        prevOdooKey !== odoo_key ||
        prevOdooUrl !== odoo_url) &&
      feature &&
      feature.payslip === true &&
      odooStatus !== true ?
        true :
        false;

    const statusDisabled = () => {
      if (isSuperAdmin) {
        return false;
      } else {
        if (isEdit) {
          return true;
        } else {
          return false;
        }
      }
    };

    const statusExpiredDisabled = () => {
      if (isSuperAdmin) {
        if (isEdit) {
          if (parent_name) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (isEdit) {
          return true;
        } else {
          return false;
        }
      }
    };

    const renderMethodAdminOptions = () => {
      const options = [
        {id: 'paid', name: 'Paid'},
        {id: 'unpaid', name: 'Unpaid'},
      ];
      return options;
    };

    return (
      <Dialog
        open={this.state.modalFormShow}
        onClose={(event, reason) =>
          this.handleFormToggle('close', event, reason)
        }
        maxWidth="md"
      >
        <ModalTitle
          title={titleDialog}
          onClose={(event, reason) =>
            this.handleFormToggle('close', event, reason)
          }
        />
        <form onSubmit={this.handleSubmit}>
          <DialogContent className={classes.dialogContent}>
            <MInput
              name="name"
              label="Company Name *"
              placeholder="Enter company name"
              autoComplete="off"
              disabled={statusDisabled()}
              defaultValue={name}
              onBlur={(event) => {
                this.handleChangeAddress(event, 0);
                this.handleInputChange(event);
              }}
              error={
                errorMessage && errorMessage.name ?
                  errorMessage.name :
                  undefined
              }
            />
            <MInput
              name="npwp_id"
              label="NPWP ID *"
              placeholder="Enter NPWP ID"
              autoComplete="off"
              disabled={statusDisabled()}
              defaultValue={npwp_id}
              onBlur={(event) => {
                this.handleInputNPWP(event);
              }}
              error={
                errorMessage && errorMessage.npwp_id ?
                  errorMessage.npwp_id :
                  undefined
              }
            />
            <MInput
              name="npwp_holder_name"
              label="NPWP Holder Name *"
              placeholder="Enter NPWP Holder name"
              autoComplete="off"
              disabled={statusDisabled()}
              defaultValue={npwp_holder_name}
              onBlur={(event) => {
                this.handleInputNPWP(event);
              }}
              error={
                errorMessage && errorMessage.npwp_holder_name ?
                  errorMessage.npwp_holder_name :
                  undefined
              }
            />
            <MSelect
              classNameFC={classes.formControl}
              name="organization_type_id"
              label="Type / Business Industry *"
              placeholder="Select company type"
              keyPair={['id', 'name']}
              disabled={statusDisabled()}
              options={orgTypeData ? orgTypeData : []}
              value={organization_type_id}
              onChange={this.handleInputChange}
              error={
                errorMessage && errorMessage.organization_type_id ?
                  errorMessage.organization_type_id :
                  undefined
              }
              loading={modalLoading}
            />
            <MInput
              name="pic_name"
              label="PIC Name *"
              placeholder="Enter PIC Name"
              autoComplete="off"
              disabled={statusDisabled()}
              defaultValue={pic_name}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.pic_name ?
                  errorMessage.pic_name :
                  undefined
              }
            />
            <MInput
              name="pic_number"
              label="PIC Phone *"
              placeholder="Enter PIC Number"
              autoComplete="off"
              disabled={statusDisabled()}
              defaultValue={pic_number}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.pic_number ?
                  errorMessage.pic_number :
                  undefined
              }
              type={'number'}
            />
            <MInput
              name="pic_email"
              label="PIC Email *"
              placeholder="Enter PIC email"
              autoComplete="off"
              disabled={statusDisabled()}
              defaultValue={pic_email}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.pic_email ?
                  errorMessage.pic_email :
                  undefined
              }
              type={'email'}
            />
            {isSuperAdmin ? (
              <MInput
                classNameFC={`${classes.formControl}`}
                name="user_limit"
                label="User Limit *"
                placeholder="User Limit"
                type="number"
                disabled={statusExpiredDisabled()}
                defaultValue={statusExpiredDisabled() ? null : user_limit}
                onBlur={this.handleInputChange}
                error={
                  errorMessage && errorMessage.user_limit ?
                    errorMessage.user_limit :
                    undefined
                }
                inputProps={{step: 'any'}}
              />
            ) : null}
            {/* {isSuperAdmin ? (
              !isEdit ? (
                <MInput
                  classNameFC={`${classes.formControl}`}
                  name="user_limit"
                  label="User Limit"
                  placeholder="User Limit"
                  type="number"
                  disabled={statusDisabled()}
                  defaultValue={user_limit}
                  onBlur={this.handleInputChange}
                  error={errorMessage && errorMessage.user_limit ? errorMessage.user_limit : undefined}
                  inputProps={{step: 'any'}}
                />
              ) : (
                user_limit ? (
                  <MInput
                    classNameFC={`${classes.formControl}`}
                    name="user_limit"
                    label="User Limit"
                    placeholder="User Limit"
                    type="number"
                    disabled={statusDisabled()}
                    defaultValue={user_limit}
                    onBlur={this.handleInputChange}
                    error={errorMessage && errorMessage.user_limit ? errorMessage.user_limit : undefined}
                    inputProps={{step: 'any'}}
                  />
                ) : null
              )
            ) : null} */}
            {isSuperAdmin ? (
              isEdit ? (
                <Grid container>
                  <Box
                    sx={{width: '100%', typography: 'body1'}}
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: '1px solid rgb(0 0 0 / 30%)',
                      borderRadius: '4px',
                      marginTop: '16px',
                      marginBottom: '8px',
                    }}
                  >
                    <TabContext value={locationsTab}>
                      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList
                          onChange={(event, newValue) =>
                            this.handleChangeTab(newValue, 'locationsTab')
                          }
                          aria-label="location"
                          variant="scrollable"
                          scrollButtons="auto"
                          allowScrollButtonsMobile
                          indicatorColor="primary"
                          disabled={statusDisabled()}
                        >
                          {location &&
                            (location ?
                              location.map((value, idx) => {
                                return (
                                  <Tab key={idx}
                                    label={
                                        value.name ?
                                          value.name
                                              .split(' ')
                                              .slice(0, 4)
                                              .join(' ') +
                                            (value.name.split(' ').length > 4 ?
                                              '..' :
                                              '') :
                                          'Branch ' + (idx + 1)
                                    }
                                    value={idx}
                                  />
                                );
                              }) :
                              null)}
                        </TabList>
                      </Box>
                      {location &&
                        (location ?
                          location.map((value, idx) => {
                            return (
                              <>
                                <TabPanel value={idx} style={{padding: 0}}>
                                  <Grid container>
                                    <Grid xs={12}>
                                      <MInput
                                        name="address"
                                        label="Address"
                                        disabled={statusDisabled()}
                                        placeholder="Company address"
                                        defaultValue={value.address}
                                        onBlur={(event) =>
                                          this.handleChangeAddress(event, idx)
                                        }
                                        error={
                                            errorMessage && errorMessage.address ?
                                              errorMessage.address :
                                              undefined
                                        }
                                        multiline
                                        rows={2}
                                        rowsMin={2}
                                        rowsMax={5}
                                      />
                                    </Grid>
                                    <Grid xs={4} spacing={2}>
                                      <MInput
                                        classNameFC={`${classes.formControl} ${classes.pr15}`}
                                        name="lat"
                                        label="Latitude"
                                        disabled={statusDisabled()}
                                        placeholder="Input latitude"
                                        type="number"
                                        defaultValue={value?.lat}
                                        onBlur={(event) =>
                                          this.handleChangeAddress(event, idx)
                                        }
                                        error={
                                            errorMessage &&
                                            errorMessage.latitude ?
                                              errorMessage.latitude :
                                              undefined
                                        }
                                        inputProps={{step: 'any'}}
                                      />
                                    </Grid>
                                    <Grid xs={4} spacing={2}>
                                      <MInput
                                        classNameFC={`${classes.formControl} ${classes.pr15}`}
                                        name="long"
                                        disabled={statusDisabled()}
                                        label="Longitude"
                                        placeholder="Input longitude"
                                        type="number"
                                        defaultValue={value.long}
                                        onBlur={(event) =>
                                          this.handleChangeAddress(event, idx)
                                        }
                                        error={
                                            errorMessage &&
                                            errorMessage.longitude ?
                                              errorMessage.longitude :
                                              undefined
                                        }
                                        inputProps={{step: 'any'}}
                                      />
                                    </Grid>
                                    <Grid xs={4} spacing={2}>
                                      <MInput
                                        name="radius"
                                        label="GPS Radius (meter)"
                                        disabled={statusDisabled()}
                                        placeholder="Max GPS radius"
                                        type="number"
                                        defaultValue={value.radius}
                                        onBlur={(event) =>
                                          this.handleChangeAddress(event, idx)
                                        }
                                        error={
                                            errorMessage && errorMessage.radius ?
                                              errorMessage.radius :
                                              undefined
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </TabPanel>
                              </>
                            );
                          }) :
                          null)}
                    </TabContext>
                  </Box>
                </Grid>
              ) : null
            ) : null}
            {isSuperAdmin ? (
              <MInput
                name="price"
                type="text"
                label="Amount"
                placeholder="Enter Amount"
                disabled={statusDisabled()}
                autoComplete="off"
                value={price}
                onChange={this.handleInputChangeCurrency}
                error={
                  errorMessage && errorMessage.price ?
                    errorMessage.price :
                    undefined
                }
              />
            ) : null}
            {isSuperAdmin && isEdit ? (
              <MSelect
                classNameFC={classes.formControl}
                name="status"
                label="Status"
                placeholder="Select status"
                keyPair={['id', 'name']}
                disabled={statusDisabled()}
                options={
                  renderMethodAdminOptions() ? renderMethodAdminOptions() : []
                }
                value={status}
                onChange={this.handleInputChange}
                error={
                  errorMessage && errorMessage.status ?
                    errorMessage.status :
                    undefined
                }
                loading={modalLoading}
              />
            ) : null}
            {isSuperAdmin ? (
              <MDatepicker
                className={classes.formControl}
                name="expired_date"
                label="Expired Date"
                disabled={statusExpiredDisabled()}
                value={expired_date}
                onDateChange={(params) => this.handleInvoiceDateChange(params)}
                error={
                  errorMessage && errorMessage.expired_date ?
                    errorMessage.expired_date :
                    undefined
                }
                minDate={new Date()}
                placeholder="dd-mm-yyyy"
                inputVariant="outlined"
                views={['year', 'month', 'date']}
                fullWidth
              />
            ) : null}
            {isSuperAdmin ? (
            <Autocomplete
              disablePortal
              className={classes.formControl}
              disableClearable
              id="combo-box-demo"
              value={partner_name}
              options={optionData}
              onChange={(event, newValue) => {
                this.setState({
                  formData: {
                    ...this.state.formData,
                    partner_name: newValue.label,
                    partner_id: newValue.id,
                  },
                });
              }}
              renderInput={(params) => <TextField {...params}
                helperText={errorMessage && errorMessage.partner_name ? `${errorMessage.partner_name}` : null}
                error={errorMessage && errorMessage.partner_name ? true : false}
                label="Partner"
                variant="outlined"
              />
              }
            />
            ) : null}
            {isSuperAdmin ? (
              <MCheckboxGroup
                label="Select Active Features"
                error={
                  errorMessage && errorMessage.feature ?
                    errorMessage.feature :
                    undefined
                }
                row
              >
                <MCheckboxItem
                  name="office"
                  label="Office Working"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.office : undefined}
                />
                <MCheckboxItem
                  name="remote"
                  label="Remote Working"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.remote : undefined}
                />
                <MCheckboxItem
                  name="business"
                  label="Business Trip"
                  disabled={statusDisabled()}
                  onChange={(event) =>
                    this.handleCheckboxChange(event, advanceFeat)
                  }
                  checked={feature ? feature.business : undefined}
                />
                <MCheckboxItem
                  name="overtime"
                  label="Overtime"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.overtime : undefined}
                />
                <MCheckboxItem
                  name="paidleave"
                  label="Leave"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.paidleave : undefined}
                />
                <MCheckboxItem
                  name="permit"
                  label="Permit"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.permit : undefined}
                />
                <MCheckboxItem
                  name="break"
                  label="Break"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.break : undefined}
                />
                <MCheckboxItem
                  name="shift"
                  label="Shift"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.shift : undefined}
                />
                {/* <MCheckboxItem
                  name="employee_facilities"
                  label="Employee Facilities"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.employee_facilities : undefined}
                /> */}
                <MCheckboxItem
                  name="reimburse"
                  label="Reimburse"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.reimburse : undefined}
                />
                <MCheckboxItem
                  name="advantech"
                  label="Advantech"
                  disabled={statusDisabled()}
                  onChange={(event) =>
                    this.handleCheckboxChange(event, advanceFeat)
                  }
                  checked={feature ? feature.advantech : undefined}
                />
                <MCheckboxItem
                  name="payslip"
                  label="Odoo Connect"
                  disabled={statusDisabled()}
                  onChange={(event) =>
                    this.handleCheckboxChange(event, advanceFeat)
                  }
                  checked={feature ? feature.payslip : undefined}
                />
                <MCheckboxItem
                  name="approval"
                  label="Approval Order"
                  disabled={statusDisabled()}
                  onChange={(event) =>
                    this.handleCheckboxChange(event, advanceFeat)
                  }
                  checked={feature ? feature.approval : undefined}
                />
                <MCheckboxItem
                  name="multiple_hr"
                  label="Multiple HR"
                  disabled={statusDisabled()}
                  onChange={(event) =>
                    this.handleCheckboxChange(event, advanceFeat)
                  }
                  checked={feature ? feature.multiple_hr : undefined}
                />
                <MCheckboxItem
                  name="checkout_business_trip"
                  label="Checkout Business Trip"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.checkout_business_trip : undefined}
                />
                <MCheckboxItem
                  name="pph23"
                  label="PPh23"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.pph23 : undefined}
                />
                <MCheckboxItem
                  name="pagiiEvent"
                  label="Pagii Event"
                  disabled={statusDisabled()}
                  onChange={this.handleCheckboxChange}
                  checked={feature ? feature.pagiiEvent : undefined}
                />
                {
                  featureFlag.smart_office.enable_feature &&
                  <MCheckboxItem
                    name="smart_office"
                    label="Smart Office"
                    disabled={statusDisabled()}
                    onChange={this.handleCheckboxChange}
                    checked={feature ? feature.smart_office : undefined}
                  />
                }
                {/* <MCheckboxItem
                  name="tenant_management"
                  label="Tenant Management"
                  disabled={statusDisabled()}
                  onChange={(event) =>
                    this.handleCheckboxChange(event, advanceFeat)
                  }
                  checked={feature ? feature.tenant_management : undefined}
                /> */}
              </MCheckboxGroup>
            ) : null}
            {/* <p className={classes.featureNote}>*Required (select at least 1 type)<br/>**Optional</p> */}
            {isSuperAdmin ?
              advanceFeat &&
                advanceFeat.length > 0 && (
                <Grid container>
                  <Box
                    sx={{width: '100%', typography: 'body1'}}
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: '1px solid rgb(0 0 0 / 30%)',
                      borderRadius: '4px',
                      marginTop: '16px',
                      marginBottom: '8px',
                    }}
                  >
                    <TabContext value={featuresTab}>
                      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList
                          onChange={(event, newValue) =>
                            this.handleChangeTab(newValue, 'featuresTab')
                          }
                          aria-label="location"
                          variant="scrollable"
                          scrollButtons="auto"
                          allowScrollButtonsMobile
                          indicatorColor="primary"
                          disabled={statusDisabled()}
                        >
                          {advanceFeat &&
                              (advanceFeat ?
                                advanceFeat.map((value, idx) => {
                                  return (
                                    <Tab key={idx}
                                      label={
                                          value.name === 'payslip' ?
                                            'Odoo Connect' :
                                              value.name === 'multiple_hr' ?
                                                'Multiple HR' :
                                                    value.name
                                      }
                                      value={idx}
                                    />
                                  );
                                }) :
                                null)}
                        </TabList>
                      </Box>
                      {advanceFeat &&
                          (advanceFeat ?
                            advanceFeat.map((value, idx) => {
                              return (
                                <>
                                  {!modalLoading &&
                                      showBusinessLimit &&
                                      value.name === 'business' && (
                                    <TabPanel
                                      value={idx}
                                      style={{padding: 0}}
                                    >
                                      <Grid container>
                                        <MInput
                                          name="business_limit"
                                          label="Business Trip Limit"
                                          placeholder="Input maximum number of business trip check-in count"
                                          value={business_limit}
                                          onChange={this.handleInputChange}
                                          error={
                                                errorMessage &&
                                                errorMessage.business_limit ?
                                                  errorMessage.business_limit :
                                                  undefined
                                          }
                                          type="number"
                                          disabled={statusDisabled()}
                                        />
                                      </Grid>
                                    </TabPanel>
                                  )}
                                  {value.name === 'advantech' && (
                                    <TabPanel
                                      value={idx}
                                      style={{padding: 0}}
                                    >
                                      <Grid container>
                                        <MInput
                                          name="adv_key"
                                          label="Advantech Username"
                                          disabled={statusDisabled()}
                                          placeholder="Username"
                                          autoComplete="off"
                                          defaultValue={adv_key}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.adv_key ?
                                                errorMessage.adv_key :
                                                undefined
                                          }
                                          type="none"
                                        />
                                        <MInput
                                          name="adv_value"
                                          label="Advantech Password"
                                          disabled={statusDisabled()}
                                          placeholder="Password"
                                          autoComplete="off"
                                          defaultValue={adv_value}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.adv_value ?
                                                errorMessage.adv_value :
                                                undefined
                                          }
                                          type={
                                              this.state.showPassword ?
                                                'none' :
                                                'password'
                                          }
                                          InputProps={{
                                            endAdornment: (
                                              <IconButton
                                                position="end"
                                                onClick={
                                                  this.handleTogglePassword
                                                }
                                              >
                                                {!this.state.showPassword ? (
                                                    <Visibility />
                                                  ) : (
                                                    <VisibilityOff />
                                                  )}
                                              </IconButton>
                                            ),
                                          }}
                                        />
                                        <MInput
                                          name="adv_url"
                                          label="Advantech URL Public"
                                          disabled={statusDisabled()}
                                          placeholder="URL"
                                          autoComplete="off"
                                          defaultValue={adv_url}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.adv_url ?
                                                errorMessage.adv_url :
                                                undefined
                                          }
                                          type="url"
                                        />
                                        <MInput
                                          name="adv_url_local"
                                          label="Advantech URL Local"
                                          disabled={statusDisabled()}
                                          placeholder="URL"
                                          autoComplete="off"
                                          defaultValue={adv_url_local}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.adv_url_local ?
                                                errorMessage.adv_url_local :
                                                undefined
                                          }
                                          type="url"
                                        />
                                      </Grid>
                                    </TabPanel>
                                  )}
                                  {value.name === 'payslip' && (
                                    <TabPanel
                                      value={idx}
                                      style={{padding: 0}}
                                    >
                                      <Grid container direction="column">
                                        <MInput
                                          name="odoo_key"
                                          label="Odoo Api Key"
                                          disabled={statusDisabled()}
                                          placeholder="Odoo Api Key"
                                          autoComplete="off"
                                          defaultValue={odoo_key}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.odoo_key ?
                                                errorMessage.odoo_key :
                                                undefined
                                          }
                                          type="none"
                                        />
                                        <MInput
                                          name="odoo_url"
                                          label="Odoo URL"
                                          disabled={statusDisabled()}
                                          placeholder="Odoo URL"
                                          autoComplete="off"
                                          defaultValue={odoo_url}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.odoo_url ?
                                                errorMessage.odoo_url :
                                                undefined
                                          }
                                          type="url"
                                        />
                                        <Grid container alignItems="center">
                                          <MButton
                                            className={classes.defaultBtn}
                                            label={'Check Odoo'}
                                            icon={<Refresh />}
                                            onClick={this.checkOdoo}
                                            disabled={!odoo_key && !odoo_url}
                                            loading={isLoading ? true : false}
                                            style={{
                                              textTransform: 'none',
                                              margin: '5px 0px 5px 0px',
                                            }}
                                          />
                                          <Typography
                                            style={{
                                              color: odooStatus ?
                                                  'green' :
                                                  'red',
                                              marginLeft: 5,
                                            }}
                                          >
                                            {odooMessage}
                                          </Typography>
                                        </Grid>
                                        <Divider
                                          style={{
                                            marginTop: '16px',
                                            marginBottom: '5px',
                                          }}
                                        />
                                        <MInput
                                          name="payslip_limit"
                                          label="Payslip Limit"
                                          disabled={statusDisabled()}
                                          placeholder="Payslip Limit"
                                          autoComplete="off"
                                          defaultValue={payslip_limit}
                                          onBlur={
                                            this.handleInputChangeAdvantech
                                          }
                                          error={
                                              errorMessage &&
                                              errorMessage.payslip_limit ?
                                                errorMessage.payslip_limit :
                                                undefined
                                          }
                                          type="number"
                                        />
                                      </Grid>
                                    </TabPanel>
                                  )}
                                  {!modalLoading &&
                                      showMultipleHRLimit &&
                                      value.name === 'multiple_hr' && (
                                    <TabPanel
                                    value={idx}
                                    style={{padding: 0}}
                                  >
                                    <Grid container>
                                      <MInput
                                        name="hr_limit"
                                        label="HR Limit"
                                        placeholder="Input maximum number of HR Account"
                                        value={hr_limit}
                                        onChange={this.handleInputChange}
                                        error={
                                              errorMessage &&
                                              errorMessage.hr_limit ?
                                                errorMessage.hr_limit :
                                                undefined
                                        }
                                        type="number"
                                        disabled={statusDisabled()}
                                      />
                                    </Grid>
                                  </TabPanel>
                                  )}
                                </>
                              );
                            }) :
                            null)}
                    </TabContext>
                  </Box>
                </Grid>
              ) :
              null}
            {isSuperAdmin ? (
              <MInput
                name="logo"
                label="Logo"
                type="file"
                disabled={statusDisabled()}
                onChange={this.handleImageChange}
                error={
                  errorImageSize ?
                    errorImageSize :
                    errorMessage && errorMessage.logo ?
                    errorMessage.logo :
                    undefined
                }
              />
            ) : null}
            {logoURL && (
              <CardMedia
                title="Photo"
                image={logoURL}
                className={classes.logo}
              />
            )}
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            {disabled && (
              <span>
                <i>*Please make sure the Odoo connection is succeed</i>
              </span>
            )}
            <MButton
              label="Cancel"
              onClick={() => this.handleFormToggle('close')}
            />
            {statusDisabled() ? null : (
              <MButton
                className={classes.defaultBtn}
                label={titleDialog}
                icon={<Send />}
                type="submit"
                loading={isLoading ? true : false}
                disabled={disabled}
              />
            )}
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  render() {
    const {
      classes,
      isLoading,
      organizationData,
      organizationMeta,
      isSuperAdmin,
      generalSettingData,
    } = this.props;
    const {resetDatatable, resetPage, formData, showInvitationModal, anchorElBadge, fossBillingStatus} =
      this.state;
      const isOpenBadge = Boolean(anchorElBadge);

    // console.log(this.state.formData.partner_name);
    // console.log(this.state.formData.partner_id);


    const columns = [
      {name: 'no', label: 'No', display: true}, // params[0]
      {name: 'id', label: 'id', display: false}, // params[1]
      {
        name: 'name', // params[2]
        label: 'Organization Name',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value}</div>;
        },
      },
      {
        name: 'partner_name', // params[3]
        label: 'Partner',
        display: true,
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
      {name: 'user_limit', label: 'User Limit', display: isSuperAdmin}, // params[4]
      {
        name: 'total_user', // params[5]
        label: 'Total Users',
        display: true,
        customBodyRender: (value, rowData) => {
          return value ? value : '-';
        },
      },
      {
        name: 'last_activity', // params[6]
        label: 'Last Activity Date',
        display: true,
        sort: false,
        customBodyRender: (value, rowData) => {
          const params = rowData && rowData.rowData;
          return value ? (
            <Button
              variant="outlined"
              size="small"
              onClick={() => this.handleAnalytics(params)}
              style={{whiteSpace: 'nowrap'}}
            >
              {convDate(value, 'DD-MM-YYYY')}
            </Button>
          ) : (
            '-'
          );
        },
      },
      {
        name: 'last_plan', // params[7]
        label: 'Current Plan',
        display: isSuperAdmin,
        customBodyRender: (value) => {
          return value ? value + 'Month(s)' : '-';
        },
      },
      {
        name: 'expired_date', // params[8]
        label: 'Expired Date',
        display: isSuperAdmin,
        customBodyRender: (value) => {
          return value ? (
            <div style={{whiteSpace: 'nowrap'}}>
              {convDate(value, 'DD-MM-YYYY')}
            </div>
          ) : (
            '-'
          );
        },
      },
      {
        name: 'status', // params[9]
        label: 'Status',
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!isSuperAdmin && value) {
            return value === 'paid' ?
              <span style={{fontWeight: '700'}} className="text-success">{value.charAt(0).toUpperCase() + value.slice(1)} </span> :
              value === 'unpaid' ?
              <span style={{fontWeight: '700'}} className="text-warning">{value.charAt(0).toUpperCase() + value.slice(1)} </span> :
              <span style={{fontWeight: '700'}} className="text-danger">{value.charAt(0).toUpperCase() + value.slice(1)} </span>;
          } else if (isSuperAdmin) {
            return <>
              <Button
                id="basic-button"
                aria-controls={isOpenBadge ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isOpenBadge ? 'true' : undefined}
                style={fossBillingStatus ? {cursor: 'pointer'} : null}
                // onClick={this.handleClickBadge}
                className={value === 'paid' ? classes.badgePaid : value === 'unpaid' ? classes.badgeUnpaid : classes.badgeInvalid}
                onClick={(e) => {
                  if (fossBillingStatus) {
                    return;
                  } else {
                    this.setState({
                      anchorElBadge: e.currentTarget,
                      badgeOption: value === 'paid' ? 'Unpaid' : 'Paid',
                      formInvoice: {
                        id: tableMeta.rowData[1],
                        company_name: tableMeta.rowData[2],
                        bank_name: null,
                        payment_date: null,
                        amount: tableMeta.rowData[38],
                        partner: null,
                        plan: tableMeta.rowData[7],
                        payment_method: 'manual_transfer',
                        payment_status: 'Approved',
                      },
                    });
                  }
                }}
                endIcon={!fossBillingStatus ? <ExpandMore/> : <></>}
              >
                {value}
              </Button>
              <MuiThemeProvider theme={this.badgeTheme()}>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElBadge}
                  open={isOpenBadge}
                  onClose={this.handleCloseBadge}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  // className={classes.badgeUnpaid}
                >
                  <MenuItem
                    onClick={() => {
                      this.handleCloseBadge();
                      if (this.state.badgeOption === 'Paid') {
                        this.setState({
                          modalChangeInvoiceStatus: true,
                        });
                      } else if (this.state.badgeOption === 'Unpaid') {
                        showPopup(
                            'confirm',
                            `Are you sure you want to change this status to unpaid?`,
                            'Yes',
                            () => this.props.updateInvoiceStatus(this.state.formInvoice.id, {status: 'unpaid'}, 'unpaid'),
                        );
                      }
                    }}
                  >
                    {this.state.badgeOption ? this.state.badgeOption : ''}
                  </MenuItem>
                </Menu>
              </MuiThemeProvider>
            </>;
          }
        },
      },
      {
        name: 'stage', // params[10]
        label: 'Stage',
        display: isSuperAdmin,
        customBodyRender: (value) => {
          if (value === 'account') {
            return (
              <strong style={{color: '#008000', textTransform: 'capitalize'}}>
                {value}
              </strong>
            );
          } else if (value === 'prospect') {
            return (
              <strong style={{color: '#FD7224', textTransform: 'capitalize'}}>
                {value}
              </strong>
            );
          } else if (value === 'followup') {
            return (
              <strong style={{color: '#FFC226', textTransform: 'capitalize'}}>
                {value}
              </strong>
            );
          } else if (value === 'open') {
            return (
              <strong style={{color: '#3CA1C1', textTransform: 'capitalize'}}>
                {value}
              </strong>
            );
          } else {
            return (
              <strong style={{color: '#3CA1C1', textTransform: 'capitalize'}}>
                -
              </strong>
            );
          }
        },
      },


      {name: 'parent_name', label: 'Parent Name', display: false}, // params[11]
      {name: 'country_code', label: 'Country', display: false}, // params[12]
      {
        name: 'ot_name', // params[13]
        label: 'Company Type',
        display: false,
        customBodyRender: (value) => {
          if (value === 'account') {
            return (
              <strong style={{color: '#008000', textTransform: 'capitalize'}}>
                {value}
              </strong>
            );
          } else if (value === 'prospect') {
            return (
              <strong style={{color: '#FD7224', textTransform: 'capitalize'}}>
                {value}
              </strong>
            );
          } else if (value === 'followup') {
            return (
              <strong style={{color: '#FFC226', textTransform: 'capitalize'}}>
                {value}
              </strong>
            );
          } else if (value === 'open') {
            return (
              <strong style={{color: '#3CA1C1', textTransform: 'capitalize'}}>
                {value}
              </strong>
            );
          } else {
            return (
              <strong style={{textTransform: 'capitalize'}}>
                {value}
              </strong>
            );
          }
        },
      },
      {name: 'address', label: 'Address', display: false}, // params[14]
      {name: 'description', label: 'Description', display: false}, // params[15]
      {
        name: 'image', // params[16]
        label: 'Logo',
        display: false,
        customBodyRender: (value) => {
          return (
            <Link href={value} target="_blank" rel="noopener noreferrer">
              {/* <CardMedia
                className={`${classes.modalAvatar} ${classes.imgSize}`}
                alt="check out picture"
                image={value}
                title='Check Out'
                component='image'
              /> */}
              <img src={value} style={{width: '40px'}} alt="completed"/>
            </Link>
          );
        },
      },
      {name: 'employees', label: 'Employees', display: false}, // params[17]
      {name: 'feature', label: 'Feature', display: false}, // params[18]
      {name: 'business_limit', label: 'Business Limit', display: false}, // params[19]
      {name: 'latitude', label: 'Latitude', display: false}, // params[20]
      {name: 'longitude', label: 'Longitude', display: false}, // params[21]
      {name: 'radius', label: 'radius', display: false}, // params[22]
      {
        name: 'invoice', // params[23]
        label: 'Invoice Date',
        display: false,
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
      {
        name: 'plan', // params[24]
        label: 'Next Plan',
        display: false,
        customBodyRender: (value) => {
          return value ? value + 'Month(s)' : '-';
        },
      },
      {name: 'adv_key', label: 'Advantech Username', display: false}, // params[25]
      {name: 'adv_value', label: 'Advantech Password', display: false}, // params[26]
      {name: 'adv_url', label: 'Advantech URL', display: false}, // params[27]
      {name: 'location', label: 'location', display: false}, // params[28]
      {name: 'adv_url_local', label: 'Advantech URL Local', display: false}, // params[29]
      {name: 'odoo_api_key', label: 'Odoo Api Key', display: false}, // params[30]
      {name: 'odoo_url', label: 'Odoo Url', display: false}, // params[31]
      {name: 'payslip_limit', label: 'Payslip Limit', display: false}, // params[32]
      {
        name: 'created_at', // params[33]
        label: 'Register Date',
        display: isSuperAdmin ? false : true,
        customBodyRender: (value) => {
          return value ? (
            <div style={{whiteSpace: 'nowrap'}}>
              {convDate(value, 'DD-MM-YYYY')}
            </div>
          ) : (
            '-'
          );
        },
      },
      {
        name: 'hr_admin', // params[34]
        label: 'Admin Email',
        display: isSuperAdmin ? false : true,
        customBodyRender: (value) => {
          return value ? value : '-';
        },
      },
      {
        name: 'total_user', // params[35]
        label: 'Total Company',
        display: false,
        customBodyRender: (value, rowData) => {
          return value && rowData && rowData.rowData && rowData.rowData[16] ?
            value + '/' + rowData.rowData[16] :
            value ?
            value :
            '-';
        },
      },
      {name: 'partner_id', label: 'Partner ID', display: false}, // params[36]
      {name: 'hr_limit', label: 'HR Limit', display: false}, // params[37]
      {name: 'price', label: 'Price', display: false}, // params[38]
      {name: 'npwp_id', label: 'NPWP ID', display: false}, // params[39]
      {name: 'npwp_holder_name', label: 'NPWP Holder Name', display: false}, // params[40]
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <InvitationOrganizationModal
          props={this}
          showModal={showInvitationModal}
          formData={formData}
          isSuperAdmin={isSuperAdmin}
          classes={classes}
          isLoading={isLoading}
          showPassword={this.state.showPassword}
          handleChangeAddress={(param) => this.handleChangeAddress(param)}
          handleSubmit={this.handleInvitationSubmit}
          handleChangeTab={(param) => this.handleChangeTab(param)}
          handleInputChange={(param) => this.handleInputChange(param)}
          handleInputChangeCurrency={(param) =>
            this.handleInputChangeCurrency(param)
          }
          handleInvoiceDateChange={(param) =>
            this.handleInvoiceDateChange(param)
          }
          handleCheckboxChange={(param) => this.handleCheckboxChange(param)}
          handleInputChangeAdvantech={(param) =>
            this.handleInputChangeAdvantech(param)
          }
          handleTogglePassword={(param) => this.handleTogglePassword(param)}
          checkOdoo={() => this.checkOdoo}
          handleImageChange={(param) => this.handleImageChange(param)}
        />
        <Datatable
          title="Organization List"
          loading={isLoading}
          dataSource={organizationData}
          total={organizationMeta.total}
          page={organizationMeta.page}
          columns={columns}
          customActions={(data) => this.renderActions(data)}
          handleReload={(params) => this.handleReload(params)}
          handleCreate={() => this.handleFormToggle('add')}
          // handleEdit={(data) => this.handleEditDialogToggle(data)}
          // handleDelete={(data) => this.handleConfirmDelete(data)}
          handleSearch={(params) => this.handleSearch(params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
          customTools={
            !isSuperAdmin ? (
              <div style={{float: 'right'}}>
                <Grid container justify="center">
                  <Tooltip title="Invitation">
                    <IconButton
                      onClick={() =>
                        this.setState({showInvitationModal: true})
                      }
                      disabled={isLoading ? true : false}
                    >
                      <InsertInvitationOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </div>
            ) : null
          }
        />
        {this.renderForm()}
        {isSuperAdmin ? this.renderModalChangeInvoiceStatus() : ''}
        {this.renderGenerateInvoice()}
      </div>
    );
  }
}

const stateToProps = ({
  organization,
  auth,
  userManagement,
  register,
  invoice,
  partner,
  bank,
  generalSetting,
}) => ({
  auth: auth,
  userManagement: userManagement,
  isSuperAdmin: checkSuperadmin(auth),
  isLoading: organization.isLoading,
  isError: organization.isError,
  isSuccess: organization.isSuccess,
  message: organization.message,
  trigger: organization.trigger,
  errors: organization.errors,
  organizationData: organization.organizationData,
  organizationMeta: organization.organizationMeta,
  modalLoading: organization.modalLoading,
  orgAdminData: organization.orgAdminData,
  orgAdminLoading: organization.orgAdminLoading,
  orgTypeData: organization.orgTypeData,
  registerTrigger: register.trigger,
  registerMessage: register.message,
  invoiceData: invoice.invoiceData,
  isErrorInvoice: invoice.isError,
  isLoadingInvoice: invoice.isLoading,
  isSuccessInvoice: invoice.isSuccess,
  messageInvoice: invoice.message,
  triggerInvoice: invoice.trigger,
  userFeature: userManagement.menus,

  isLoadingPartner: partner.isLoading,
  isSuccessPartner: partner.isSuccess,
  triggerPartner: partner.trigger,
  partnerList: partner.partnerList,
  exportLoading: organization.exportLoading,
  exportData: organization.exportData.file,

  isLoadingBank: bank.isLoading,
  isSuccessBank: bank.isSuccess,
  isError: bank.isErrorBank,
  messageBank: bank.message,
  triggerBank: bank.trigger,
  bankData: bank.bankData,
  bankMeta: bank.bankMeta,

  isLoadingPartner: partner.isLoading,
  isSuccessPartner: partner.isSuccess,
  isError: partner.isErrorPartner,
  messagePartner: partner.message,
  triggerPartner: partner.trigger,
  partnerData: partner.partnerList,

  generalSettingData: generalSetting.data,
  isLoadingGeneralSetting: generalSetting.isLoading,
  isSuccessGeneralSetting: generalSetting.isSuccess,
  isErrorGeneralSetting: generalSetting.isError,
  messageGeneralSetting: generalSetting.message,
  triggerGeneralSetting: generalSetting.trigger,
});

const dispatchToProps = (dispatch) => ({
  login: (params) => dispatch(login(params)),
  getAllOrganization: (params) => dispatch(getAllOrganization(params)),
  getChildOrganizationForHR: (params) =>
    dispatch(getChildOrganizationForHR(params)),
  generateInvoice: (params) => dispatch(generateInvoice(params)),
  getOrganizationType: () => dispatch(getOrganizationType()),
  getAdmins: () => dispatch(getAdmins()),
  checkStatus: () => dispatch(checkStatus()),
  createOrganization: (data) => dispatch(createOrganization(data)),
  createChildOrganization: (id, data) =>
    dispatch(createChildOrganization(id, data)),
  updateOrganization: (data, id) => dispatch(updateOrganization(data, id)),
  deleteOrganization: (id) => dispatch(deleteOrganization(id)),
  deleteChildOrganization: (params) =>
    dispatch(deleteChildOrganization(params)),
  invitationOrganization: (params) => dispatch(invitationOrganization(params)),
  submitApproval: (params) => dispatch(submitApproval(params)),
  clearState: () => dispatch(clearState()),
  clearStateGenerate: () => dispatch(clearStateGenerate()),
  getInvoice: (params) => dispatch(getInvoice(params)),
  checkOdoo: (params) => dispatch(checkOdoo(params)),
  exportExcel: (params) => dispatch(exportExcel(params)),

  updateInvoiceStatus: (id, params, status) => dispatch(updateInvoiceStatus(id, params, status)),
  getBankList: (params) => dispatch(getBankList(params)),
  getPartnerList: (params) => dispatch(getPartnerList(params)),
  getGeneralSetting: (params) => dispatch(getGeneralSetting(params)),
  assignOrganizationHR: (params) => dispatch(assignOrganizationHR(params)),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(withStyles(styles)(Organization));
