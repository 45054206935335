import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Typography,
  Button,
  Tooltip,
  MuiThemeProvider,
  Dialog,
  DialogContent,
  Grid,
  createTheme as createMuiTheme,
} from "@material-ui/core";
import { Paper } from '@mui/material'
import {
  Cancel,
} from "@material-ui/icons";
import styles from "../styles";
import {
  ModalTitle,
} from "../../../../components/form";
import {
  allBlastingNotif,
  detailBlastingNotif,
  updateBlastingNotif,
} from "../../../../redux/blastingNotif/blastingNotif.action";
import { Datatable } from "../../../../components";
import { Edit, ScheduleSend, Search } from "@mui/icons-material";
import { convDate } from "../../../../helpers";
import BlastingNotifForm from "./form"

class BlastingNotif extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_detail: false,
      filterDatatable: {
        page: 1,
        limit: 10,
      },
      blastingData: [],
      blastingMeta: {
        total: 10,
        totalPages: 1,
        page: "1",
        perPage: "10"
      }
    };
  }

  paperRef = createRef()

  resetIsDetailState = () => {
    this.setState((prevState) => ({
      ...prevState,
      is_detail: false
    }))
  }

  fetchDatatable = () => {
    const {page, limit} = this.state.filterDatatable;
    this.props.allBlastingNotif({
      ...(page && {page}),
      ...(limit && {limit}),
    })
  }

  fetchDetail = (id, isDetail) => {
    this.props.detailBlastingNotif(id)

    if (isDetail === true) {
      this.setState((prevState) => ({
        ...prevState,
        is_detail: isDetail,
      }))
    }
  }

  componentWillReceiveProps(next) {
    if (next.isSuccess && next.isSuccess !== this.props.isSuccess) {
      this.fetchDatatable()
    }
  }

  UNSAFE_componentWillMount() {
    this.fetchDatatable();
  }

  componentDidUpdate(prevProps, prevState) {
    const { blastingData, blastingMeta } = this.props;
    const { filterDatatable } = this.state;

    if (prevProps.blastingData !== blastingData) {
      this.setState((prevState) => ({
        ...prevState,
        blastingData: blastingData,
        blastingMeta: blastingMeta,
      }))
    }

    if (prevState.filterDatatable !== filterDatatable) {
      this.fetchDatatable()
    }
  }

  handleReload = (params) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        filterDatatable: {
          ...prevState.filterDatatable,
          page: params.page,
          limit: params.limit,
        }
      })
    );
  }

  dialogTheme = () => createMuiTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiDialogTitle: {
        root: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
  })

  renderModal = () => {
    const { is_detail } = this.state;
    const { classes, blastingDetail } = this.props;

    if (!blastingDetail) return null;

    let target = ''
    let schedule = ''

    switch (blastingDetail.target_type) {
      case 'user':
        target = 'Specific Users';
        break;
      case 'organization':
        target = 'Organizations';
        break;
      default:
        target = 'All Users';
        break;
    }

    switch (blastingDetail.schedule_type) {
      case 'now':
        schedule = 'Now';
        break;
      default:
        schedule = 'Scheduled';
        break;
    }

    return (
      <MuiThemeProvider theme={this.dialogTheme()}>
        <Dialog
          open={is_detail}
          onClose={() => {
            this.setState(prevState => ({
              ...prevState,
              is_detail: false,
            }))
          }}
          maxWidth='md'
          fullWidth={true}
          scroll='paper'
          PaperProps={{
            style: {borderRadius: 15, paddingTop: 20, paddingBottom: 20},
          }}
        >
          <ModalTitle
            className={classes.modalTitle}
            title="Blasting Notification Detail"
            onClick={() => {
              this.setState(prevState => ({
                ...prevState,
                is_detail: false,
              }))
            }}
            style={{display: 'flex', alignSelf: 'center'}}
          />
          <DialogContent className={classes.dialogContent}>
            <div>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Subject </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{blastingDetail.subject}</Typography></Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Message </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{blastingDetail.message}</Typography></Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Target Type </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{target}</Typography></Grid>
              </Grid>
              {blastingDetail.target_type === 'user' ? (
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={2}><Typography>Users </Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  <Grid item xs zeroMinWidth><Typography>{
                    blastingDetail.user_data.map((e) => {return e.name}).join(', ')
                  }</Typography></Grid>
                </Grid>
              ) : blastingDetail.target_type === 'organization' ? (
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={2}><Typography>Organizations </Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  <Grid item xs zeroMinWidth><Typography>{
                    blastingDetail.organizations.map((e) => {return e.name}).join(', ')
                  }</Typography></Grid>
                </Grid>
              ) : null}
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Notification Type </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{
                  JSON
                    .parse(blastingDetail.notification_type)
                    .map((e) => {
                      switch (e) {
                        case 'push_notif':
                          return 'Push Notification'
                        default:
                          return '-'
                      }
                    })
                    .join(', ')
                }</Typography></Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Schedule Type </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{schedule}</Typography></Grid>
              </Grid>
              {blastingDetail.schedule_type !== 'now' && blastingDetail.schedule_date_time !== null ? (
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={2}><Typography>Schedule Date & Time </Typography></Grid>
                  <Grid item><Typography> : </Typography></Grid>
                  <Grid item xs zeroMinWidth><Typography>{convDate(blastingDetail.schedule_date_time, 'DD-MM-YYYY HH:mm:ss')} </Typography></Grid>
                </Grid>
              ) : null}
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Status </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{blastingDetail.status}</Typography></Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }

  renderActions = (params) => {
    const detail = (
      <div style={{display: 'inline', marginRight: '5px'}}>
        <Tooltip title="Detail">
          <Button
            variant="contained"
            size="small"
            style={{
              'backgroundColor': '#00aff0',
              'boxShadow': 'none',
              'color': '#fff',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#D09F53',
              },
              'minWidth': '10px',
              'paddingRight': '0px',
            }}
            onClick={() => this.fetchDetail(params[1], true)}
            startIcon={<Search />}>
          </Button>
        </Tooltip>
      </div>
    );
    const edit = (
      <div style={{display: 'inline', marginRight: '5px'}}>
        <Tooltip title="Edit">
          <Button
            variant="contained"
            style={{
              'backgroundColor': '#008000',
              'boxShadow': 'none',
              'color': '#fff',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#007000',
              },
              'minWidth': '10px',
              'paddingRight': '0px',
            }}
            size="small"
            onClick={() => this.fetchDetail(params[1], false)}
            startIcon={<Edit />}
          >
          </Button>
        </Tooltip>
      </div>
    );

    const canceled = (
      <div style={{display: 'inline', marginRight: '5px'}}>
        <Tooltip title="Canceled">
          <Button
            variant="contained"
            size="small"
            style={{
              'backgroundColor': '#ff5733',
              'boxShadow': 'none',
              'color': '#fff',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#D09F53',
              },
              'minWidth': '10px',
              'paddingRight': '0px',
            }}
            onClick={() => this.props.updateBlastingNotif(params[1], {status: 'canceled'})}
            startIcon={<Cancel />}>
          </Button>
        </Tooltip>
      </div>
    );

    switch (params[6]) {
      case 'waiting':
        return (
          <div style={{whiteSpace: 'nowrap'}}>
            {detail}
            {canceled}
          </div>
        );
      case 'draft':
        return (
          <div style={{whiteSpace: 'nowrap'}}>
            {detail}
            {edit}
            {canceled}
          </div>
        );
      default:
        return (
          <div style={{whiteSpace: 'nowrap'}}>
            {detail}
          </div>
        );
    }
  };

  render() {
    const { classes, isLoading } = this.props;
    const { blastingData, blastingMeta } = this.state;

    const columns = [
      {
        name: 'id',
        display: false,
        customBodyRender: (value) => (value ? value : null),
      },
      {
        name: 'subject',
        label: 'Subject',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'target_type',
        label: 'Target Type',
        display: true,
        customBodyRender: (value) => {
          switch (value) {
            case 'all':
              return 'All'
            case 'user':
              return 'User'
            case 'organization':
              return 'Organization'
            default:
              return '-';
          };
        },
      },
      {
        name: 'schedule_type',
        label: 'Schedule Type',
        display: true,
        customBodyRender: (value) => {
          switch (value) {
            case 'now':
              return 'Now'
            case 'scheduled':
              return 'Scheduled'
            default:
              return '-';
          };
        },
      },
      {
        name: 'schedule_date_time',
        label: 'Schedule Date',
        display: true,
        customBodyRender: (value) => {
          return value ? (
            <Button
              variant="outlined"
              size="small"
              startIcon={<ScheduleSend className={classes.scheduleDateTime} />}
            >
              {convDate(value, 'DD-MM-YYYY HH:mm')}
            </Button>
          ) : (
            '-'
          );
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) => {
          switch (value) {
            case 'sent':
              return <strong style={{color: '#008000'}}>{'Sent'}</strong>;
            case 'waiting':
              return <strong style={{color: '#FFC226'}}>{'Waiting'}</strong>;
            case 'draft':
              return <strong style={{color: '#E5AF5A'}}>{'Draft'}</strong>;
            case 'canceled':
              return <strong style={{color: '#F00B38'}}>{'Canceled'}</strong>;
            default:
              return '-';
          }
        }
      },
    ];

    return (
      <div className={classes.root} style={{display: "flex", flexDirection: "column", gap: 10}}>
        <BlastingNotifForm is_detail={this.state.is_detail} resetForm={this.resetForm}/>
        <Paper variant="outlined" className={classes.paper}>
        <Datatable
          title={'Blasting Notification History'}
        
          loading={isLoading}
          dataSource={blastingData}
          total={blastingMeta ? blastingMeta.total : 0}
          page={blastingMeta ? blastingMeta.page : 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          customActions={(params) => this.renderActions(params)}
          creatable={false}
          paging={true}
        />
        </Paper>
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = ({ blastingNotif }) => ({
  blastingData: blastingNotif.blastingData,
  blastingMeta: blastingNotif.blastingMeta,
  blastingDetail: blastingNotif.blastingDetail,
  isLoading: blastingNotif.isFetchLoading,
  isSuccess: blastingNotif.isSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  allBlastingNotif: (params) => dispatch(allBlastingNotif(params)),
  detailBlastingNotif: (params) => dispatch(detailBlastingNotif(params)),
  updateBlastingNotif: (id, params) => dispatch(updateBlastingNotif(id, params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BlastingNotif));
